import { Dispatch } from "redux";

import { Api } from "../../lib/API";
import * as ValueTypesActions from './action';
import * as UserActions from '../User/action';
import { GetValueTypesInfoResult, GetValueTypeLookupsResult } from "./types";



const loadValueTypesInfo = (token: string) => async (dispatch : Dispatch) => {

    const responseValueTypes = await Api.AUTHENTICATED_GET<GetValueTypesInfoResult, any>('api/v1/config/valuetypes', token);
    if (responseValueTypes.type === "auth_error") 
    {
        dispatch(UserActions.userLogoutSuccess());
    }
    else if (responseValueTypes.type === "error" || responseValueTypes.type === "server_error")
    {           
        dispatch(ValueTypesActions.loadValueTypesInfoFailure([]));
    }
    else
    {
        const responseLookups = await Api.AUTHENTICATED_GET<GetValueTypesInfoResult, any>('api/v1/config/valuetypelookups', token);
        if (responseLookups.type === "auth_error") 
        {
            dispatch(UserActions.userLogoutSuccess());
        }
        else if (responseLookups.type === "error" || responseLookups.type === "server_error")
        {           
            dispatch(ValueTypesActions.loadValueTypesInfoFailure([]));
        }
        else
        {
            const actionPayload: GetValueTypesInfoResult & GetValueTypeLookupsResult = {
                valueTypes: responseValueTypes.result.valueTypes,
                lookups: responseLookups.result.lookups
            };
            dispatch(ValueTypesActions.loadValueTypesInfoSuccess(actionPayload));
        }
    }
}


const operations = {
    loadValueTypesInfo
};

export default operations;