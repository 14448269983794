import { createActions } from 'redux-actions';

export const {
  userLogout,
  loadAssetsActualValuesRequest,
  loadAssetsActualValuesSuccess,
  loadAssetsActualValuesFailure
} = createActions(
  'USER_LOGOUT',
  'LOAD_ASSETS_ACTUAL_VALUES_REQUEST',
  'LOAD_ASSETS_ACTUAL_VALUES_SUCCESS',
  'LOAD_ASSETS_ACTUAL_VALUES_FAILURE'
);
