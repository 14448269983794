import { handleActions } from 'redux-actions';

import { SetChartDataActionType, TimelineDataState, SetCurrentFullIntervalActionType, SetCurrentTimestampActionType } from './types';

const initialState: TimelineDataState = {
  loadDataInfo: {
    isLoadingData: true,
    errorLoadingData: false
  },
  batches: [],
  dataPoints: [],
  currentFullInterval: { s: 0, f: 0},
  currentTimestamp: 0,
  chartTimestamps: [],
  missingDataIntervals: [],
  numberAssetsExpected: 0
}

const TimelineDataReducer = handleActions<any>(
  {
    USER_LOGOUT: (state: any, action: any) => {
      return initialState;
    },

    SET_CHART_DATA_REQUEST: (state: any, action: SetChartDataActionType) => {
      return {
        ...state,
        loadDataInfo: {
          isLoadingData: true,
          errorLoadingData: false
        },
      }
    },

    SET_CHART_DATA_FAILURE: (state: any, action: SetChartDataActionType) => {
      return {
        ...state,
        loadDataInfo: {
          isLoadingData: false,
          errorLoadingData: true
        },
      }
    },

    SET_CHART_DATA_SUCCESS: (state: any, action: SetChartDataActionType) => {
      let newState: TimelineDataState = {
        ...state,
        loadDataInfo: {
          isLoadingData: false,
          errorLoadingData: false
        },
        currentTimestamp: action.payload.currentTimestamp,
        missingDataIntervals: []
      };

      // console.log("SET_CHART_DATA - currentFullInterval", action.payload.currentFullInterval);
      // console.log("SET_CHART_DATA - currentTimestamp", action.payload.currentTimestamp);
      //console.log("SET_CHART_DATA - numberAssetsExpected", action.payload.numberAssetsExpected);
      
      if (action.payload.currentFullInterval) {
          newState.currentFullInterval = action.payload.currentFullInterval;
          newState.dataPoints = action.payload.dataPoints;
      }
      else { // partial - adds datapoint or replace value if already exists
        if (action.payload.batches.length > 0) {
          for (let i = 0; i < action.payload.batches.length; i++) {
            const batch = action.payload.batches[i];
            const dataPointFound = newState.dataPoints.filter(sdp => sdp.assetId === batch.aID && sdp.valueTypeId === batch.vtID);
            dataPointFound.forEach(point => {
              const dataPointIndex = newState.dataPoints.indexOf(point);
              newState.dataPoints.splice(dataPointIndex, 1);
            });
          }
          newState.batches = action.payload.batches;
        }

        action.payload.dataPoints.forEach(dp => {
          const dataPointFound = newState.dataPoints.find(sdp => sdp.assetId === dp.assetId && sdp.valueTypeId === dp.valueTypeId && sdp.timestamp === dp.timestamp);
          if (dataPointFound) {
            //console.log("SET_CHART_DATA - datapoint found");
            dataPointFound.value = dp.value;
          }
          else {
            //console.log("SET_CHART_DATA - datapoint not found");
            newState.dataPoints = newState.dataPoints.concat(dp);
          }
        });
      }

      // chartTimestamps
      const minimumTimestampToSearch = newState.currentFullInterval.s + (5 * 60);
      const maximumTimestampToSearch = action.payload.currentTimestamp / 1000;
      
      const activePowerDataPoints = action.payload.dataPoints.filter(d => d.timestamp <= maximumTimestampToSearch && d.valueTypeId === 16);

      for (let timeIndex = minimumTimestampToSearch; 
          timeIndex <= maximumTimestampToSearch;
          timeIndex = timeIndex + (5 * 60)) {

        const key = timeIndex * 1000;

        if (!newState.chartTimestamps[key]) {
          newState.chartTimestamps[key] = [];
        }

        activePowerDataPoints.filter(d => d.timestamp === timeIndex)
          .forEach(d => {
            if (newState.chartTimestamps[key].findIndex(assetId => assetId === d.assetId) === -1) {
              newState.chartTimestamps[key].push(d.assetId);
            }
          });

        //console.log("SET_CHART_DATA - chartTimestamps.length", newState.chartTimestamps[key].length);
        if (newState.chartTimestamps[key].length < action.payload.numberAssetsExpected) {
          newState.missingDataIntervals.push({
            s: timeIndex - (5 * 60),
            f: timeIndex
          });
        }
      }

      //console.log("newState", newState);
      return newState;
    },

    SET_CURRENT_TIMESTAMP: (state: any, action: SetCurrentTimestampActionType) => {
      const newState: TimelineDataState = {
        ...state,
        currentTimestamp: action.payload
      };

      return newState;
    },

    SET_CURRENT_FULL_INTERVAL: (state: any, action: SetCurrentFullIntervalActionType) => {
      const newState: TimelineDataState = {
        ...state,
        currentFullInterval: {...action.payload}
      };

      return newState;
    },

  },
  initialState,
);
 
export default TimelineDataReducer;
