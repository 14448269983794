import * as React from 'react';

import { addModifiers } from '../../helpers';
import { FormattedMessageHelper } from '../../helpers/internationalization';

import './Title.scss';
import LeftAreaCollapseExpand from '../LeftAreaCollapseExpand/LeftAreaCollapseExpand';

interface ComponentProps  {
  title: string,
  modifiers?: string[],
  showCollapseAction?: boolean,
  handleCollapseClick?: any
}

class Title extends React.Component<ComponentProps> {

  render() {
    return (
      <h2 className={addModifiers('whs-title', this.props.modifiers)}>
        <div className="title-table">
          <div className="title-text">
            <FormattedMessageHelper id={this.props.title}/>
          </div>
          { this.props.showCollapseAction &&
          <div className="collapse-button">
            <LeftAreaCollapseExpand />
          </div>
          }
        </div>
      </h2>);
  }
}

export default Title;