import { handleActions } from 'redux-actions';
import { ChangeLeftAreaViewActionType, NavigationState, CollapseLeftAreaViewActionType, ExpandLeftAreaViewActionType } from './types';

const initialState: NavigationState = {
    leftAreaView: {
        viewName: "CLIENTS_LIST",
        viewObjId: -1
    },
    leftAreaCollapsed: false
};

const NavigationReducer = handleActions<any>(
    {
        SET_LEFT_AREA_VIEW: (state: any, action: ChangeLeftAreaViewActionType) => {
            return {
                ...state,
                leftAreaView: {
                    viewName: action.payload.viewName,
                    viewObjId: action.payload.viewObjId
                }
            };
        },
  
        COLLAPSE_LEFT_AREA_VIEW: (state: any, action: CollapseLeftAreaViewActionType) => {
            return {
                ...state,
                leftAreaCollapsed: true
            };
        },
  
        EXPAND_LEFT_AREA_VIEW: (state: any, action: ExpandLeftAreaViewActionType) => {
            return {
                ...state,
                leftAreaCollapsed: false
            };
        },
    },
    initialState,
);
  
export default NavigationReducer;
  