import { handleActions } from 'redux-actions';

import { PropsUserReducerType } from './types';

const initialState: PropsUserReducerType = {
  loginStatus: localStorage.getItem('token') !== null ? 'LOGIN_SUCCESS' : 'ANONYMOUS'
};

const UserReducer = handleActions<any>(
  {
    USER_LOGIN_REQUEST:(state: any, action: any) => {
      return {
        ...state,
        loginStatus: 'LOGIN_STARTED'
      };
    },
    USER_LOGIN_SUCCESS :(state: any, action: any) => {
      localStorage.setItem('token', action.payload.token);
      return {
        ...state,
        loginStatus: 'LOGIN_SUCCESS'
      };
    },
    USER_LOGIN_FAILED:(state: any, action: any) => { 
      return {
        ...state,
        loginStatus: 'LOGIN_FAILED'
      };
    },
    USER_LOGIN_FAILURE:(state: any, action: any) => { 
      return {
        ...state,
        loginStatus: 'LOGIN_FAILURE'
      };
    },

    USER_LOGOUT_REQUEST:(state: any, action: any) => {
      return {
        ...state,
        loginStatus: 'LOGOUT_STARTED'
      };
    },
    USER_LOGOUT_SUCCESS:(state: any, action: any) => { 
      localStorage.clear();
      return {
        ...state,
        loginStatus: 'ANONYMOUS'
      };
    },
    USER_LOGOUT_FAILURE:(state: any, action: any) => { 
      return {
        ...state,
        loginStatus: 'LOGIN_SUCCESS'
      };
    },
  },
  initialState,
);

export default UserReducer;
