export const localeData: any = {
  'pt': {
    'error.boundary.1': 'Ocorreu um erro na Aplicação',
    'error.boundary.2': 'Por favor faça refresh a pagina',
    'error.boundary.3': 'Obrigado.',
    'notFound.404': 'Pagina não encontrada',
    
    'loadingdata.message': 'A carregar os dados...',
    'error.message': 'Ocorreu um erro na Aplicação. Por favor, tente mais tarde.',

    'client.title': 'Lista de Clientes',
    'chart.title': 'Gráfico de Produção',
    'warnings.title': 'Avisos',

    'client.nodatamessage': 'Não foram retornados clientes',
    'chart.nodatamessage': 'Não foram retornados dados para o gráfico',
    'warnings.nodatamessage': 'Sem avisos',

    'chart.legend.energyproduction': 'Produção de energia',
    'chart.legend.energypredicted': 'Previsão de produção',
    'chart.legend.fare': 'Tarifa',
    'chart.legend.fareexpected': 'Tarifa prevista',
    
    'chart.tooltip.production': 'Produção',
    'chart.tooltip.productionexpected': 'Previsão de Produção',
    'chart.tooltip.productionvsexpected': 'Potência activa vs prevista vs expected',
    'chart.tooltip.fare': 'Tarifa',
    
    'clients.actives': 'Activos',
    'clients.production': 'Produção',
    'clients.selection': 'Selecção',

    'assets.list.active': 'Activo',
    'assets.list.production': 'Produção',
    'assets.list.equips.ok': 'Equips Ok',

    'assets.list.item.equips.expression': '{equipsOk} de {equipsTotal}',

    'asset.details.button.back': 'Voltar',
    'asset.details.power': 'Potência',
    'asset.details.production': 'Produção',
    'asset.details.resource': 'Recurso',
    'asset.details.lastcommunication': 'Última Comunicação',
    'asset.details.equips': 'Equipamentos Ok',
    'asset.details.equips.expression': '{equipsOk} de {equipsTotal}',
    'asset.details.setpoint': 'Setpoint',
    'asset.details.setpointfeedback': 'Feedback do Setpoint',
    'asset.details.limitationcause': 'Causa da Limitação',
    'asset.details.limitationcausefeedback': 'Feedback da Causa da Limitação',
    'asset.details.monthlyaveragefare': 'Tarifa Média Mensal',
    'asset.details.marketaveragefare': 'Tarifa Média Mercado',
    'asset.details.monthlyaveragefarelastmonth': 'Tarifa Média Mensal (M-1)',
    'asset.details.marketaveragefarelastmonth': 'Tarifa Média Mercado (M-1)',

    'login.title': 'Login',
    'login.fields.username': 'Utilizador',
    'login.fields.password': 'Password',
    'login.button': 'Login',
    'login.waiting': 'A autenticar...',
    'login.failed': 'Username ou password inválidos!',
  },
  'en': {
    'error.boundary.1': 'An Application Error Has Occurred',
    'error.boundary.2': 'Please try refreshing the page',
    'error.boundary.3': 'Thanks.',
    'notFound.404': 'Page not Found',

    'loadingdata.message': 'Loading data...',
    'error.message': 'An application error has occurred. Please try again later.',

    'client.title': 'Client list',
    'chart.title': 'Production Chart',
    'warnings.title': 'Warnings',

    'client.nodatamessage': 'No clients returned',
    'chart.nodatamessage': 'No chart data returned',
    'warnings.nodatamessage': 'No warnings',

    'chart.legend.energyproduction': 'Energy production',
    'chart.legend.energypredicted': 'Production predicted',
    'chart.legend.fare': 'Tariff',
    'chart.legend.fareexpected': 'Tariff expected',
    
    'chart.tooltip.production': 'Energy Production',
    'chart.tooltip.productionexpected': 'Production predicted',
    'chart.tooltip.productionvsexpected': 'Energy production vs expected',
    'chart.tooltip.fare': 'Tariff',
    
    'clients.actives': 'Actives',
    'clients.production': 'Production',
    'clients.selection': 'Selection',

    'assets.list.active': 'Active',
    'assets.list.production': 'Production',
    'assets.list.equips.ok': 'Equips Ok',

    'assets.list.item.equips.expression': '{equipsOk} of {equipsTotal}',

    'asset.details.button.back': 'Back',
    'asset.details.power': 'Power',
    'asset.details.production': 'Production',
    'asset.details.resource': 'Resource',
    'asset.details.lastcommunication': 'Last Communication',
    'asset.details.equips': 'Equipments Ok',
    'asset.details.equips.expression': '{equipsOk} of {equipsTotal}',
    'asset.details.setpoint': 'Setpoint',
    'asset.details.setpointfeedback': 'Setpoint Feedback',
    'asset.details.limitationcause': 'Limitation Cause',
    'asset.details.limitationcausefeedback': 'Limitation Cause Feedback',
    'asset.details.monthlyaveragefare': 'Monthly Average Fare',
    'asset.details.marketaveragefare': 'Market Average Fare',
    'asset.details.monthlyaveragefarelastmonth': 'Monthly Average Fare (M-1)',
    'asset.details.marketaveragefarelastmonth': 'Market Average Fare (M-1)',

    'login.title': 'Login',
    'login.fields.username': 'Username',
    'login.fields.password': 'Password',
    'login.button': 'Login',
    'login.waiting': 'Signing in...',
    'login.failed': 'Username or password invalid!',
  },
};

export const datesForHighCharts: any = {
  en: {
    weekdays: [
      'Sunday', 'Monday', 'Tuesday', 'Wednesday',
      'Thursday', 'Friday', 'Saturday',
    ],
    months: [
      'January' , 'February' , 'March' , 'April',
      'May' , 'June' , 'July' , 'August' , 'September',
      'October' , 'November' , 'December',
    ],
    shortMonths: [
      'Jan' , 'Feb' , 'Mar' , 'Apr',
      'May' , 'Jun' , 'Jul' , 'Aug',
      'Sep' , 'Oct' , 'Nov' , 'Dec',
    ],
  },
  pt: {
    weekdays: [
      'Domingo', 'Segunda', 'Terça', 'Quarta',
      'Quinta', 'Sexta', 'Sábado',
    ],
    months: [
      'Janeiro' , 'Fevereiro' , 'Março' , 'Abril',
      'Maio' , 'Junho' , 'Julho' , 'Agosto' ,
      'Setembro', 'Outubro' , 'Novembro' , 'Dezembro',
    ],
    shortMonths: [
      'Jan' , 'Fev' , 'Mar' , 'Abr',
      'Mai' , 'Jun' , 'Jul' , 'Ago',
      'Set' , 'Out' , 'Nov' , 'Dec',
    ],
  },
};
