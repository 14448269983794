import * as React from 'react';
import { Checkbox } from '../Forms/Checkbox';

import  '../AssetsList/AssetsList.scss';
import { AssetState, ClientsAssetsState } from '../../store/ClientsAssetsInfo/types';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import operations from '../../store/ClientsAssetsInfo/operations';
import navOperations from '../../store/Navigation/operations';
import { Store } from '../../helpers/types';
import  * as clientAssetsSelectors  from '../../store/ClientsAssetsInfo/selectors';
import  * as valueTypesInfoSelectors  from '../../store/ValueTypesInfo/selectors';
import  * as actualValuesSelectors  from '../../store/ActualValues/selectors';
import { AssetIcon } from '../AssetIcon/AssetIcon';
import { ProgressBar } from '../ProgressBar/ProgressBar';
import { ValueTypeInfo } from '../../store/ValueTypesInfo/types';
import { AssetActualValuesState, ActualValue } from '../../store/ActualValues/types';
import * as valueTypesHelper from '../../helpers/valuetypeshelper';
import { FormattedMessageHelper } from '../../helpers/internationalization';

interface ComponentProps {
    assetId: number,
    clientAndAssets?: ClientsAssetsState,
    valueTypesInfo?: ValueTypeInfo[],
    actualValues?: AssetActualValuesState,
    dispatchChangeAssetState?: any,
    dispatchClickAssetName?: any
}

interface State {
}

class AssetsListItemComponent extends React.Component<ComponentProps, State> {

    handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
        //console.log( "asset event", event.target.value, event.target.checked );
        this.props.dispatchChangeAssetState(Number(event.target.value), event.target.checked);
    }

    handleClickAssetName = (event: React.ChangeEvent<HTMLInputElement>) => {
        //console.log( "click asset name");
        this.props.dispatchClickAssetName(this.props.assetId);
    }

    getAsset(): AssetState | undefined {
        if (this.props.clientAndAssets && this.props.clientAndAssets.assetsState) {
            return this.props.clientAndAssets.assetsState.find(a => a.assetInfo.assetId === this.props.assetId);
        }
        return undefined;
    }

    getAssetActualValues(asset: AssetState): any {
        const actualValues = this.props.actualValues ? this.props.actualValues.values : [];
        const assetActualValues: ActualValue[] = 
            valueTypesHelper.getAssetActualValues(this.props.assetId, actualValues) || [];

        const assetReferenceValueTypeId: number = asset && asset.assetInfo.referenceValueTypeId
                ? asset.assetInfo.referenceValueTypeId : -1;

        return {
            equipsOk: valueTypesHelper.getActualValueAndUnit("Asset.EquipmentsOK", assetActualValues, this.props.valueTypesInfo),
            equipsTotal: valueTypesHelper.getActualValueAndUnit("Asset.EquipmentsTotal", assetActualValues, this.props.valueTypesInfo),
            activePower: valueTypesHelper.getActualValueAndUnit("Asset.ActivePower", assetActualValues, this.props.valueTypesInfo),
            maxActivePower: valueTypesHelper.getActualValueAndUnit("Asset.MaxActivePower", assetActualValues, this.props.valueTypesInfo),
            activeSetpoint: valueTypesHelper.getActualValueAndUnit("Asset.ActiveSetpoint", assetActualValues, this.props.valueTypesInfo),
            referenceActualValue: valueTypesHelper.getActualValueAndUnitByValueTypeId(assetReferenceValueTypeId, assetActualValues, this.props.valueTypesInfo),
        };
    }


    render(): JSX.Element {
        const asset = this.getAsset();

        if (!asset) {
            return <div />
        }

        const assetType = asset.assetInfo.assetTypeCode;
        const color = asset.assetInfo.displayColor ? asset.assetInfo.displayColor : "";

        const values = this.getAssetActualValues(asset);
        const powerPercentage = valueTypesHelper.getPercentageForValueAndUnits(values.activePower, values.maxActivePower);
        const activeSetpointPercentage = valueTypesHelper.getPercentageForValueAndUnits(values.activeSetpoint, values.maxActivePower);

        const activePowerDisplayValue = valueTypesHelper.buildValueAndUnitJSXElement(
                <div>
                    <div className="production-value" style={{color: color}}>
                        {valueTypesHelper.formatNumericValueToDisplay(values.activePower) + " " + valueTypesHelper.formatUnitToDisplay(values.activePower)}
                    </div>
                </div>,
                values.activePower);

        const assetReferenceDisplayValue = valueTypesHelper.buildValueAndUnitJSXElement(
            <span>
                {valueTypesHelper.formatNumericValueToDisplay(values.referenceActualValue)
                + " " +
                valueTypesHelper.formatUnitToDisplay(values.referenceActualValue)}
            </span>,
            values.referenceActualValue);
        
        const equipsDisplayValue = valueTypesHelper.buildValueAndUnitJSXElement(
            <span>
                <FormattedMessageHelper id="assets.list.item.equips.expression" values={{ equipsOk: valueTypesHelper.formatNumericValueToDisplay(values.equipsOk), equipsTotal: valueTypesHelper.formatNumericValueToDisplay(values.equipsTotal) }} />
            </span>,
            values.equipsOk && values.equipsTotal);
        

        return(
            <div className="assets__table-row">
                <div className="assets__table-cell column-active">
                <Checkbox
                    id={"asset_filter" + asset.assetInfo.assetId.toString()}
                    name={"asset_filter" + asset.assetInfo.assetId.toString()}
                    value={asset.assetInfo.assetId.toString()}
                    checked={asset.checked}
                    labelColor={color}
                    handleChange={this.handleFilter}
                    handleLabelClick={this.handleClickAssetName}
                    contentModifiers={["asset"]}
                    >
                    {asset.assetInfo.assetCode}
                </Checkbox>
                </div>
                <div className="assets__table-cell column-production">
                    <div className="column-production-cell">
                        <div className="production-power">
                            { activePowerDisplayValue }
                        </div>
                        <div className="production-bar">
                            <ProgressBar 
                                activePercentage={powerPercentage} 
                                activeSetpoint={activeSetpointPercentage}
                                color={color}/>
                        </div>
                        <div className="production-asset-icon">
                            <AssetIcon assetType={assetType} color={color} />
                        </div>
                        <div className="production-ref-measure">
                            { assetReferenceDisplayValue }
                            
                        </div>
                    </div>
                </div>
                <div className="assets__table-cell column-equip">
                    { equipsDisplayValue }
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: Store): any => ({
    clientAndAssets: clientAssetsSelectors.getClientsAndAssets(state),
    valueTypesInfo: valueTypesInfoSelectors.getValueTypes(state),
    actualValues: actualValuesSelectors.getActualValues(state)
  });

const mapDispatchToProps = (dispatch: Dispatch<any>): any => ({
    dispatchChangeAssetState: (assetId: number, checked: boolean) => dispatch(operations.changeAssetState(assetId, checked)),
    
    dispatchClickAssetName: (assetId: number) => {
        dispatch(navOperations.setLeftAreaView({ viewName: "ASSET_DETAILS", viewObjId: assetId } ));
        dispatch(operations.selectAssetExclusively(assetId));
    } 
  });

const AssetsListItem = connect(mapStateToProps, mapDispatchToProps)(AssetsListItemComponent)

export default AssetsListItem;
