import * as React from 'react';

import  './AssetsList.scss';
import { AssetState, ClientsAssetsState } from '../../store/ClientsAssetsInfo/types';
import AssetsListItem from '../AssetsListItem/AssetsListItem';
import { connect } from 'react-redux';
import { Store } from '../../helpers/types';
import  * as selectors  from '../../store/ClientsAssetsInfo/selectors';
import { FormattedMessageHelper } from '../../helpers/internationalization';

interface ComponentProps {
    clientAndAssets?: ClientsAssetsState,
    clientId: number
}

interface State {
}

class AssetsListComponent extends React.Component<ComponentProps, State> {

    getAssetsByClient(): AssetState[] {
        if (this.props.clientAndAssets && this.props.clientAndAssets.assetsState) {
            //console.log("getAssetsByClient", this.props.clientId, this.props.clientAndAssets.assetsState);
            return (this.props.clientAndAssets.assetsState)
                .filter(a => a.assetInfo.clientId === this.props.clientId);
        }
        return [];
    }
    
      render(): JSX.Element {
        return (
            <div className="assets__table">
                <div className="assets__table-heading">
                    <div className="assets__table-headrow">
                        <div className="assets__table-head assets__table-cell column-active"><FormattedMessageHelper id="assets.list.active" /></div>
                        <div className="assets__table-head assets__table-cell column-production"><FormattedMessageHelper id="assets.list.production" /></div>
                        <div className="assets__table-head assets__table-cell column-equip"><FormattedMessageHelper id="assets.list.equips.ok" /></div>
                    </div>
                </div>
                <div className="assets__table-body">
                    { (this.getAssetsByClient()).map(asset => 
                        <AssetsListItem key={asset.assetInfo.assetId} assetId={asset.assetInfo.assetId} />
                    )}
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state: Store): any => ({
    clientAndAssets: selectors.getClientsAndAssets(state)
  });
  
const AssetsList = connect(mapStateToProps, null)(AssetsListComponent)
  
  
export default AssetsList;
