import { Store } from '../../helpers/types';
import { createSelector } from 'reselect';

export const actualValuesReducerSelector = (state: Store) => state.assetActualValues;


export const getLoadInfo = createSelector(
    actualValuesReducerSelector,
    (actualValues) => {
        return actualValues.loadDataInfo;
    } 
);

export const getActualValues = createSelector(
    actualValuesReducerSelector,
    (actualValues) => {
        return actualValues;
    } 
);

