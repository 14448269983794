import * as React from 'react';

import  './LeftArea.scss';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Store } from '../../helpers/types';
import  * as selectors  from '../../store/Navigation/selectors';
import { ClientsList } from '../ClientsList';
import { LeftAreaViewInfo, NavigationState } from '../../store/Navigation/types';
import AssetDetails from '../AssetDetails/AssetDetails';

interface ComponentProps {
    navigationState?: NavigationState,
    dispatchChangeClientState?: any
}

interface State {
}

class LeftAreaComponent extends React.Component<ComponentProps, State> {

    render(): JSX.Element {
        const currentViewInfo:LeftAreaViewInfo = this.props.navigationState ?
            this.props.navigationState.leftAreaView :
            {viewName: "CLIENTS_LIST"};
            
        return(
            <div className="left-area">
                { currentViewInfo.viewName === "CLIENTS_LIST" &&
                <ClientsList />
                }
                { currentViewInfo.viewName === "ASSET_DETAILS" &&
                    currentViewInfo.viewObjId &&
                <AssetDetails assetId={currentViewInfo.viewObjId} />
                }
            </div>
        );
    }
}

const mapStateToProps = (state: Store): any => ({
    navigationState: selectors.getNavigationState(state)
  });

const mapDispatchToProps = (dispatch: Dispatch<any>): any => ({
    //dispatchChangeClientState: (clientId: number, checked: boolean) => dispatch(operations.changeClientState(clientId, checked))
  });

const LeftArea = connect(mapStateToProps, mapDispatchToProps)(LeftAreaComponent)

export default LeftArea;
