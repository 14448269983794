import { Store } from '../../helpers/types';
import { createSelector } from 'reselect';
import { ValueTypeCode } from './types';

export const valueTypesInfoReducerSelector = (state: Store) => state.valueTypes;


export const getLoadInfo = createSelector(
    valueTypesInfoReducerSelector,
    (valueTypes) => {
        return valueTypes.loadDataInfo;
    } 
);

export const getValueTypes = createSelector(
    valueTypesInfoReducerSelector,
    (valueTypes) => {
        return valueTypes.info;
    } 
);

export const getValueTypesWithAlarmsConfigured = createSelector(
    valueTypesInfoReducerSelector,
    (valueTypes) => {
        return valueTypes.info
            .filter(vt => vt.alarms.length > 0);
    } 
);

export const getValueTypesForTimeline = createSelector(
    valueTypesInfoReducerSelector,
    (valueTypes) => {
        const valueTypeCodes: ValueTypeCode[] = [
            "Chart.ActivePower",
            "Chart.ActivePowerForecast",
            "Global.Tariff",
            "Global.TariffForecast"
        ];
        return valueTypes.info
            .filter(vt => valueTypeCodes.findIndex(vtc => vtc === vt.valueTypeCode) !== -1)
            .map(vt => vt.valueTypeId);
    } 
);

export const getTariffValueTypeIds = createSelector(
    valueTypesInfoReducerSelector,
    (valueTypes) => {
        const valueTypeCodes: ValueTypeCode[] = [
            "Global.Tariff",
            "Global.TariffForecast"
        ];
        return valueTypes.info
            .filter(vt => valueTypeCodes.findIndex(vtc => vtc === vt.valueTypeCode) !== -1)
            .map(vt => vt.valueTypeId);
    } 
);

export const getActivePowerValueTypeId = createSelector(
    valueTypesInfoReducerSelector,
    (valueTypes) => {
        const valueTypeFound = valueTypes.info.find(vt => vt.valueTypeCode === "Chart.ActivePower");
        return valueTypeFound ? valueTypeFound.valueTypeId : undefined;
    } 
);

export const getForecastValueTypeId = createSelector(
    valueTypesInfoReducerSelector,
    (valueTypes) => {
        const valueTypeFound = valueTypes.info.find(vt => vt.valueTypeCode === "Chart.ActivePowerForecast");
        return valueTypeFound ? valueTypeFound.valueTypeId : undefined;
    } 
);

export const getValueTypeLookups = createSelector(
    valueTypesInfoReducerSelector,
    (valueTypes) => {
        return valueTypes.lookups;
    } 
);

