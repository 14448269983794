import * as React from 'react';
import { FormattedTime } from "react-intl";

import  './AssetDetails.scss';
import { AssetState, ClientsAssetsState } from '../../store/ClientsAssetsInfo/types';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import operations from '../../store/ClientsAssetsInfo/operations';
import navOperations from '../../store/Navigation/operations';
import { Store } from '../../helpers/types';
import  * as clientAssetsSelectors  from '../../store/ClientsAssetsInfo/selectors';
import  * as valueTypesInfoSelectors  from '../../store/ValueTypesInfo/selectors';
import  * as actualValuesSelectors  from '../../store/ActualValues/selectors';
import { AssetDetailsTitle } from '../AssetDetailsTitle';
import { AssetIcon } from '../AssetIcon/AssetIcon';
import { ProgressBar } from '../ProgressBar/ProgressBar';
import { ValueTypeInfo } from '../../store/ValueTypesInfo/types';
import { ActualValue, AssetActualValuesState } from '../../store/ActualValues/types';
import * as valueTypesHelper from '../../helpers/valuetypeshelper';
import * as dateHelper from '../../helpers/datehelper';
import buttonImage from './images/glyph-left.svg'
import { FormattedMessageHelper } from '../../helpers/internationalization';

interface ComponentProps {
    assetId: number,
    clientAndAssets?: ClientsAssetsState,
    valueTypesInfo?: ValueTypeInfo[],
    actualValues?: AssetActualValuesState,
    dispatchClickToGoBack?: any
}

interface State {
}

class AssetDetailsComponent extends React.Component<ComponentProps, State> {

    getAsset(): AssetState | undefined {
        if (this.props.clientAndAssets && this.props.clientAndAssets.assetsState) {
            return this.props.clientAndAssets.assetsState.find(a => a.assetInfo.assetId === this.props.assetId);
        }
        return undefined;
    }

    handleBackButtonClick = (event: any) => {
        //console.log( "handleBackButtonClick");
        this.props.dispatchClickToGoBack();
    };

    getAssetActualValues(asset: AssetState): any {
        const actualValues = this.props.actualValues ? this.props.actualValues.values : [];
        const assetActualValues: ActualValue[] = 
            valueTypesHelper.getAssetActualValues(this.props.assetId, actualValues) || [];
        const tariffAssetActualValues: ActualValue[] = valueTypesHelper.getTariffAssetActualValues(actualValues) || [];

        const assetReferenceValueTypeId: number = asset && asset.assetInfo.referenceValueTypeId
            ? asset.assetInfo.referenceValueTypeId : -1;

        return {
            equipsOk: valueTypesHelper.getActualValueAndUnit("Asset.EquipmentsOK", assetActualValues, this.props.valueTypesInfo),
            equipsTotal: valueTypesHelper.getActualValueAndUnit("Asset.EquipmentsTotal", assetActualValues, this.props.valueTypesInfo),
            activePower: valueTypesHelper.getActualValueAndUnit("Asset.ActivePower", assetActualValues, this.props.valueTypesInfo),
            maxActivePower: valueTypesHelper.getActualValueAndUnit("Asset.MaxActivePower", assetActualValues, this.props.valueTypesInfo),
            setpoint: valueTypesHelper.getActualValueAndUnit("Asset.ActiveSetpoint", assetActualValues, this.props.valueTypesInfo),
            setpointFeedback: valueTypesHelper.getActualValueAndUnit("Asset.FeedbackSetpoint", assetActualValues, this.props.valueTypesInfo),
            limitationCause: valueTypesHelper.getActualValueAndUnit("Asset.SetpointOrigin", assetActualValues, this.props.valueTypesInfo),
            limitationCauseFeedback: valueTypesHelper.getActualValueAndUnit("Asset.FeedbackOrigin", assetActualValues, this.props.valueTypesInfo),
            communicationStateDate: dateHelper.timestampToDate(valueTypesHelper.getMaxActualValueTimeStamp(assetActualValues)),
            monthlyAverageFare: valueTypesHelper.getActualValueAndUnit("Asset.AverageTariff", assetActualValues, this.props.valueTypesInfo),
            marketAverageFare: valueTypesHelper.getActualValueAndUnit("Asset.AverageTariff", tariffAssetActualValues, this.props.valueTypesInfo),
            monthlyAverageFareLastMonth: valueTypesHelper.getActualValueAndUnit("Asset.AverageTariffLastMonth", assetActualValues, this.props.valueTypesInfo),
            marketAverageFareLastMonth: valueTypesHelper.getActualValueAndUnit("Asset.AverageTariffLastMonth", tariffAssetActualValues, this.props.valueTypesInfo),
            referenceActualValue: valueTypesHelper.getActualValueAndUnitByValueTypeId(assetReferenceValueTypeId, assetActualValues, this.props.valueTypesInfo),
        };
    }

    buildLines = (valueDescription: string): JSX.Element[] => {
        let result = valueDescription.split('<br />').map((item, i) => {
            return i === 0 ? <span key={i}>{item}</span> : <span key={i}><br/>{item}</span>;
        });
        return result;
    }

    buildValueAndUnitDisplay = (valueAndUnit: valueTypesHelper.ValueAndUnit): JSX.Element => {
        return valueTypesHelper.buildValueAndUnitJSXElement(
            <span>{this.buildLines(valueTypesHelper.formatNumericValueToDisplay(valueAndUnit))}</span>,
            valueAndUnit);
    }


    render(): JSX.Element {
        const asset = this.getAsset();
        if (!asset) {
            return <div />
        }

        const assetType=asset.assetInfo.assetTypeCode;
        const color = asset.assetInfo.displayColor ? asset.assetInfo.displayColor : "";

        const values = this.getAssetActualValues(asset);
        const powerPercentage = valueTypesHelper.getPercentageForValueAndUnits(values.activePower, values.maxActivePower);

        const communicationStateDisplayValue = valueTypesHelper.buildValueAndUnitJSXElement(
            <FormattedTime value={values.communicationStateDate} year="numeric" month="2-digit" day="2-digit" hour='numeric' minute='numeric' second='numeric' />,
            values.communicationStateDate);


        const activePowerDisplayValue = valueTypesHelper.buildValueAndUnitJSXElement(
            <div>
                <span style={{color: color}}>
                    {valueTypesHelper.formatNumericValueToDisplay(values.activePower)}
                </span>
                <span className="asset-details__production-unit">
                    <sup> {valueTypesHelper.formatUnitToDisplay(values.activePower)}</sup>
                </span>
            </div>,
            values.activePower);

        const assetReferenceDisplayValue = valueTypesHelper.buildValueAndUnitJSXElement(
            <span>
                {valueTypesHelper.formatNumericValueToDisplay(values.referenceActualValue)
                + " " +
                valueTypesHelper.formatUnitToDisplay(values.referenceActualValue)}
            </span>,
            values.referenceActualValue);
        
    
        const equipsDisplayValue = valueTypesHelper.buildValueAndUnitJSXElement(
            <span>
                <FormattedMessageHelper id="asset.details.equips.expression" values={{ equipsOk: valueTypesHelper.formatNumericValueToDisplay(values.equipsOk), equipsTotal: valueTypesHelper.formatNumericValueToDisplay(values.equipsTotal) }} />
            </span>,
            values.equipsOk && values.equipsTotal);

        // TODO: change this to an actual variable once its in the API
        const monthlyAverageFareDisplayValue = valueTypesHelper.buildValueAndUnitJSXElement(
            <div>
                <span style={{color: color}}>
                    {valueTypesHelper.formatNumericValueToDisplay(values.monthlyAverageFare)}
                </span>
                <span className="asset-details__production-unit">
                    <sup> {valueTypesHelper.formatUnitToDisplay(values.monthlyAverageFare)}</sup>
                </span>
            </div>,
            values.monthlyAverageFare);

        const marketAverageFareDisplayValue = valueTypesHelper.buildValueAndUnitJSXElement(
            <div>
                <span style={{color: color}}>
                    {valueTypesHelper.formatNumericValueToDisplay(values.marketAverageFare)}
                </span>
                <span className="asset-details__production-unit">
                    <sup> {valueTypesHelper.formatUnitToDisplay(values.marketAverageFare)}</sup>
                </span>
            </div>,
            values.marketAverageFare);

        const monthlyAverageFareLastMonthDisplayValue = valueTypesHelper.buildValueAndUnitJSXElement(
            <div>
                <span style={{color: color}}>
                    {valueTypesHelper.formatNumericValueToDisplay(values.monthlyAverageFareLastMonth)}
                </span>
                <span className="asset-details__production-unit">
                    <sup> {valueTypesHelper.formatUnitToDisplay(values.monthlyAverageFareLastMonth)}</sup>
                </span>
            </div>,
            values.monthlyAverageFare);

        const marketAverageFareLastMonthDisplayValue = valueTypesHelper.buildValueAndUnitJSXElement(
            <div>
                <span style={{color: color}}>
                    {valueTypesHelper.formatNumericValueToDisplay(values.marketAverageFareLastMonth)}
                </span>
                <span className="asset-details__production-unit">
                    <sup> {valueTypesHelper.formatUnitToDisplay(values.marketAverageFareLastMonth)}</sup>
                </span>
            </div>,
            values.marketAverageFare);

        return(
            <div className="asset-details">
                <AssetDetailsTitle 
                    title={asset.assetInfo.assetCode} 
                    color={color}
                    assetType={assetType} />
                
                <div className="asset-details-area">

                    <div className="asset-details__backarea">
                        <a href="#" className="backbutton" onClick={this.handleBackButtonClick}>
                            <img src={buttonImage} className="icon" />
                            <FormattedMessageHelper id="asset.details.button.back" />
                        </a>
                    </div>

                    <div className="asset-details-table">
                        <div className="asset-details-row">
                            <div className="asset-details-cell-title asset-details__column-energy">
                                <FormattedMessageHelper id="asset.details.power" />
                            </div>
                            <div className="asset-details-cell-title asset-details__column-production">
                                <FormattedMessageHelper id="asset.details.production" />
                            </div>
                            <div className="asset-details-cell-title asset-details__column-resource">
                                <FormattedMessageHelper id="asset.details.resource" />
                            </div>
                        </div>
                        <div className="asset-details-row">
                            <div className="asset-details-cell asset-details__column-energy">
                                { activePowerDisplayValue }
                            </div>
                            <div className="asset-details-cell asset-details__column-production">
                                <ProgressBar 
                                    activePercentage={powerPercentage} 
                                    color={color}/>
                            </div>
                            <div className="asset-details-cell asset-details__column-resource">
                                <AssetIcon assetType={assetType} color={color} /> <div>{ assetReferenceDisplayValue }</div>
                            </div>
                        </div>
                        <div className="asset-details-row__space">
                        </div>
                    </div>

                    <div className="asset-details-table-twocolumns">
                        <div className="asset-details-row">
                            <div className="asset-details-cell-title">
                                <FormattedMessageHelper id="asset.details.lastcommunication" />
                            </div>
                            <div className="asset-details-cell-title">
                                <FormattedMessageHelper id="asset.details.equips" />
                            </div>
                        </div>
                        <div className="asset-details-row">
                            <div className="asset-details-cell">
                                {communicationStateDisplayValue}
                            </div>
                            <div className="asset-details-cell">
                                { equipsDisplayValue }
                            </div>
                        </div>
                        <div className="asset-details-row__space">
                        </div>

                        <div className="asset-details-row">
                            <div className="asset-details-cell-title">
                                <FormattedMessageHelper id="asset.details.setpoint" />
                            </div>
                            <div className="asset-details-cell-title">
                                <FormattedMessageHelper id="asset.details.setpointfeedback" />
                            </div>
                        </div>
                        <div className="asset-details-row">
                            <div className="asset-details-cell">
                                { this.buildValueAndUnitDisplay(values.setpoint) }
                            </div>
                            <div className="asset-details-cell">
                                { this.buildValueAndUnitDisplay(values.setpointFeedback) }
                            </div>
                        </div>
                        <div className="asset-details-row__space">
                        </div>

                        <div className="asset-details-row">
                            <div className="asset-details-cell-title">
                                <FormattedMessageHelper id="asset.details.monthlyaveragefare" />
                            </div>
                            <div className="asset-details-cell-title">
                                <FormattedMessageHelper id="asset.details.marketaveragefare" />
                            </div>
                        </div>
                        <div className="asset-details-row">
                            <div className="asset-details-cell">
                                { monthlyAverageFareDisplayValue }
                            </div>
                            <div className="asset-details-cell">
                                { marketAverageFareDisplayValue }
                            </div>
                        </div>
                        <div className="asset-details-row__space">
                        </div>

                        <div className="asset-details-row">
                            <div className="asset-details-cell-title">
                                <FormattedMessageHelper id="asset.details.monthlyaveragefarelastmonth" />
                            </div>
                            <div className="asset-details-cell-title">
                                <FormattedMessageHelper id="asset.details.marketaveragefarelastmonth" />
                            </div>
                        </div>
                        <div className="asset-details-row">
                            <div className="asset-details-cell">
                                { monthlyAverageFareLastMonthDisplayValue }
                            </div>
                            <div className="asset-details-cell">
                                { marketAverageFareLastMonthDisplayValue }
                            </div>
                        </div>
                        <div className="asset-details-row__space">
                        </div>
                    </div>

                    <div className="asset-details-table-onecolumn">
                        <div className="asset-details-row">
                            <div className="asset-details-cell-title">
                                <FormattedMessageHelper id="asset.details.limitationcause" />
                            </div>
                        </div>
                        <div className="asset-details-row">
                            <div className="asset-details-cell">
                                { this.buildValueAndUnitDisplay(values.limitationCause) }
                            </div>
                        </div>
                        <div className="asset-details-row__space">
                        </div>
                    </div>

                    <div className="asset-details-table-onecolumn">
                        <div className="asset-details-row">
                            <div className="asset-details-cell-title">
                                <FormattedMessageHelper id="asset.details.limitationcausefeedback" />
                            </div>
                        </div>
                        <div className="asset-details-row">
                            <div className="asset-details-cell">
                                { this.buildValueAndUnitDisplay(values.limitationCauseFeedback) }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: Store): any => ({
    clientAndAssets: clientAssetsSelectors.getClientsAndAssets(state),
    valueTypesInfo: valueTypesInfoSelectors.getValueTypes(state),
    actualValues: actualValuesSelectors.getActualValues(state)
  });

const mapDispatchToProps = (dispatch: Dispatch<any>): any => ({
    dispatchClickToGoBack: () => {
        dispatch(navOperations.setLeftAreaView({ viewName: "CLIENTS_LIST" }));
        dispatch(operations.resetToPreviousAssetsSelection());
    }
  });

const AssetDetails = connect(mapStateToProps, mapDispatchToProps)(AssetDetailsComponent)

export default AssetDetails;
