import { Dispatch } from "redux";

import * as NavigationActions from './action';
import { LeftAreaViewInfo } from "./types";

const setLeftAreaView = (newViewInfo: LeftAreaViewInfo) => async (dispatch : Dispatch) => {
    dispatch(NavigationActions.setLeftAreaView({
        viewName: newViewInfo.viewName,
        viewObjId: newViewInfo.viewObjId
    }));
}

const collapseLeftAreaView = () => async (dispatch : Dispatch) => {
    dispatch(NavigationActions.collapseLeftAreaView());
}

const expandLeftAreaView = () => async (dispatch : Dispatch) => {
    dispatch(NavigationActions.expandLeftAreaView());
}

const navOperations = {
    setLeftAreaView,
    collapseLeftAreaView,
    expandLeftAreaView
};

export default navOperations;