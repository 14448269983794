import { Dispatch } from "redux";

import { Api } from "../../lib/API";
import * as ActualValuesActions from './action';
import * as UserActions from '../User/action';
import { GetAssetsActualValuesResult, GetAssetsActualValuesParams } from "./types";
import { ValueTypeLookup } from "../ValueTypesInfo/types";



const loadActualValues = (token: string, assetIds: number[], valueTypeLookups: ValueTypeLookup[]) => async (dispatch : Dispatch) => {
    let body: GetAssetsActualValuesParams = {
        assetIds: assetIds
    }

    dispatch(ActualValuesActions.loadAssetsActualValuesRequest());
    const response = await Api.AUTHENTICATED_POST<GetAssetsActualValuesResult, GetAssetsActualValuesParams, any>('api/v1/measures/actualvalues', body, token);

    if (response.type === "auth_error") 
    {
        dispatch(UserActions.userLogoutSuccess());
    }
    else if (response.type === "error" || response.type === "server_error")
    {           
        dispatch(ActualValuesActions.loadAssetsActualValuesFailure([]));
    }
    else
    {
        dispatch(ActualValuesActions.loadAssetsActualValuesSuccess({
            actualValues: response.result,
            valueTypeLookups: valueTypeLookups
        }));
    }
}


const operations = {
    loadActualValues
};

export default operations;