import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { localeData } from '../locales/data';

import { StringTMap } from './types';

export function formatMessageHelper(intl: any, key: string , values: StringTMap<string | number> = {}) {
  return intl.formatMessage({id: key, defaultMessage: localeData.pt[key]}, values);
}

interface FormattedMessageHelperProps {
  id: string;
  values?: any;
}
export const FormattedMessageHelper = ({id , values}: FormattedMessageHelperProps) =>
  <FormattedMessage id={id}  defaultMessage={localeData.pt[id]} values={values}/>;
