import * as React from 'react';

import { FormattedMessageHelper } from '../../helpers/internationalization';
import { ErrorBoundary } from '../ErrorBoundary';

const NotFound = (): JSX.Element => (
  <ErrorBoundary showUI={true} isNotFullScreen={true}>
    <div>
      <FormattedMessageHelper id="notFound.404"  />
    </div>
  </ErrorBoundary>
);

export default NotFound;
