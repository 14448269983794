import { createActions } from 'redux-actions';

export const {
  userLoginRequest,
  userLoginSuccess,
  userLoginFailed,
  userLoginFailure,
  userLogoutRequest,
  userLogoutSuccess,
  userLogoutFailure,
} = createActions(
 'USER_LOGIN_REQUEST',
 'USER_LOGIN_SUCCESS',
 'USER_LOGIN_FAILED',
 'USER_LOGIN_FAILURE',
 'USER_LOGOUT_REQUEST',
 'USER_LOGOUT_SUCCESS',
 'USER_LOGOUT_FAILURE',
);
