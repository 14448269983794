import * as React from 'react';

import { ErrorBoundary } from '../ErrorBoundary';

import  './DataAvailability.scss';
import { connect } from 'react-redux';
import { Store } from '../../helpers/types';
import  * as clientAssetsSelectors  from '../../store/ClientsAssetsInfo/selectors';
import  * as timelineSelectors  from '../../store/TimelineData/reselectors';
import { TimelineDataState } from '../../store/TimelineData/types';


interface ComponentProps {
  selectedAssets?: number[],
  timelineData?: TimelineDataState,
}

interface State {
}

class DataAvailabilityComponent extends React.Component<ComponentProps, State> {

    linearGrandientForChart = (availability: number[]) => {
        if (availability.length === 0) {
            return "";
        }
        const percentage = 100 / availability.length;
        let count = 0;
        let linearGrandientString = 'linear-gradient(to right';
        availability.forEach((value: number) => {
            let currentColor = 'orange';
            if (value === -1) {
                currentColor = '#000000';
            }
            else if (value === 100) {
                currentColor = '#00FF00';
            } 
            else if (value === 0) {
                currentColor = '#FF0000';
            }
        
            linearGrandientString = `${linearGrandientString}, ${currentColor} ${count}%`;
            count += percentage;
        });
        
        return `${linearGrandientString})`;
    };
      
    getAvailability = (): number[] => {

        //console.log("getAvailability");
        let availabilityMap: number[] = [];

        const chartTimestamps = this.props.timelineData && this.props.timelineData.chartTimestamps ? 
            this.props.timelineData.chartTimestamps : [];
        const selectedAssetIds = this.props.selectedAssets || [];
        const currentTimestamp = this.props.timelineData ? this.props.timelineData.currentTimestamp / 1000 : 0;
        const fullIntervalEnd = this.props.timelineData ? this.props.timelineData.currentFullInterval.f : 0;

        // console.log("selectedAssetIds", selectedAssetIds);
        // console.log("chartTimestamps", chartTimestamps);

        if (selectedAssetIds.length === 0) {
            return availabilityMap;
        }

        const totalExpectedValues = selectedAssetIds.length;
        
        //console.log("total timestamps", Object.keys(chartTimestamps).length);
        Object.keys(chartTimestamps).forEach((timestamp: any) => {
            const intersectionArray = chartTimestamps[timestamp]
                .filter(assetId => selectedAssetIds.includes(assetId));
            availabilityMap.push(intersectionArray.length * 100 / totalExpectedValues);
        });

        // fills the timestamps from current to the end of full interval with -1 to fulfill the bar
        for (let timeIndex = currentTimestamp + (5 * 60);
            timeIndex <= fullIntervalEnd;
            timeIndex = timeIndex + (5 * 60)) {
                
            availabilityMap.push(-1);
        }  

        //console.log("availabilityMap", availabilityMap);
        return availabilityMap;
    }

  componentDidMount() {
    // align the bar with the first chart tick when component is first mounted
    const yLabelLeft = document.getElementsByClassName('highcharts-axis-labels highcharts-yaxis-labels')[0] as SVGGraphicsElement;
    const yLabelLeftSize = yLabelLeft.getBBox().width;
    const gradientLine = document.getElementsByClassName('gradient__line')[0] as HTMLElement;
    gradientLine.style.marginLeft = `${yLabelLeftSize - 10}px`;
  }

  componentDidUpdate() {
    // align the bar with the first chart tick when component is updated
    const gradientLine = document.getElementsByClassName('gradient__line')[0] as HTMLElement;
    const oldMargin = gradientLine.style.marginLeft;
    const yLabelLeft = document.getElementsByClassName('highcharts-axis-labels highcharts-yaxis-labels')[0] as SVGGraphicsElement;
    const yLabelLeftSize = yLabelLeft.getBBox().width;

    if (!(oldMargin === `${yLabelLeftSize - 10}px`)) {
      gradientLine.style.marginLeft = `${yLabelLeftSize - 10}px`;
    }
  }

  render(): JSX.Element {
    return (
      <ErrorBoundary showUI={true}>
          <div className="gradient__line" style={{backgroundImage: this.linearGrandientForChart(this.getAvailability())}}/>
      </ErrorBoundary>
    )
  }
}

const mapStateToProps = (state: Store): any => ({
  selectedAssets: clientAssetsSelectors.getSelectedAssetIdsWithClient(state),
  timelineData: timelineSelectors.timelineDataSelector(state),
});


const DataAvailability = connect(mapStateToProps, null)(DataAvailabilityComponent)

export default DataAvailability;
