import { Dispatch } from "redux";
import { Api } from "../../lib/API";
import * as UserActions from './action'

interface LoginParams {
    username: string;
    password: string;
}

interface LoginSuccess {
    token: string;
}

const login = (params: LoginParams) => async (dispatch: Dispatch) => {
    dispatch(UserActions.userLoginRequest());

    const response = await Api.POST<LoginParams, LoginSuccess>('api/v1/auth/login', params);
    if (response.type === "success") 
    {
        const {
            token
        } = response.result;

        dispatch(UserActions.userLoginSuccess({ token }));
    }
    else if (response.type === "error")
    {
        dispatch(UserActions.userLoginFailed())
    }
    else
    {
        dispatch(UserActions.userLoginFailure())
    }
}

const logout = (token: string) => async (dispatch: Dispatch) => {
    dispatch(UserActions.userLogoutRequest());

    const response = await Api.AUTHENTICATED_POST<null, null>('api/v1/auth/logout', null, token);
    if (response.type === "success" || response.type === "auth_error") 
    {
        dispatch(UserActions.userLogoutSuccess());
    }
    else //if (response.type === "server_error" || response.type === "error")
    {
        dispatch(UserActions.userLogoutFailure())
    }
}

const operations = {
    login,
    logout,
};

export default operations;