import { createActions } from 'redux-actions';

export const {
  userLogout,
  loadClientsAssetsInfoRequest,
  loadClientsAssetsInfoFailure,
  loadClientsAssetsInfoSuccess,
  changeAssetState,
  changeClientState,
  changeClientDetailsVisibility,
  selectAssetExclusively,
  resetToPreviousAssetsSelection
} = createActions(
  'USER_LOGOUT',
  'LOAD_CLIENTS_ASSETS_INFO_REQUEST',
  'LOAD_CLIENTS_ASSETS_INFO_FAILURE',
  'LOAD_CLIENTS_ASSETS_INFO_SUCCESS',
  'CHANGE_ASSET_STATE',
  'CHANGE_CLIENT_STATE',
  'CHANGE_CLIENT_DETAILS_VISIBILITY',
  'SELECT_ASSET_EXCLUSIVELY',
  'RESET_TO_PREVIOUS_ASSETS_SELECTION'
);
