import { combineReducers } from 'redux';

import BaseTimelineDataReducerType from './TimelineData/reducer';
import { TimelineDataReducerType } from './TimelineData/types';
import BaseUserReducerType from './User/reducer';
import { UserReducerType } from './User/types';
import BaseClientsAssetsStateReducerType from './ClientsAssetsInfo/reducer';
import { ClientsAssetsStateReducerType } from './ClientsAssetsInfo/types';
import BaseNavigationStateReducerType from './Navigation/reducer';
import { NavigationStateReducerType } from './Navigation/types';
import BaseValueTypesInfoReducerType from './ValueTypesInfo/reducer';
import { ValueTypesInfoReducerType } from './ValueTypesInfo/types';
import BaseActualValuesReducerType from './ActualValues/reducer';
import { ActualValuesReducerType } from './ActualValues/types';

type BaseStoreTypes = UserReducerType &
                      TimelineDataReducerType & 
                      ClientsAssetsStateReducerType & 
                      NavigationStateReducerType &
                      ValueTypesInfoReducerType &
                      ActualValuesReducerType;

const combinedStore = combineReducers<BaseStoreTypes>({
  user: BaseUserReducerType,
  timelineData: BaseTimelineDataReducerType,
  clientsAssetsState: BaseClientsAssetsStateReducerType,
  navigationState: BaseNavigationStateReducerType,
  valueTypes: BaseValueTypesInfoReducerType,
  assetActualValues: BaseActualValuesReducerType
});

export type combinedType = BaseStoreTypes;

export { combinedStore };
