import moment, { Moment } from "moment";
import { Interval } from "../store/TimelineData/types";

export const timestampToDate = (timestamp?: number): Date | undefined => 
    timestamp ? new Date(timestamp * 1000) : undefined;

export const getBeginOfTodayTimestamp = (): number => 
    momentToNumber(moment().startOf('day'));

export const getBeginOfTomorrowTimestamp = (): number => 
    momentToNumber(moment().add(1, 'day').startOf('day'));

export const momentToNumber = (moment: Moment): number =>
    Number(moment.format('x'));


export const getCurrentTimestamp = (): number => {
    return momentToNumber(moment()
            .minute(moment().minute() - moment().minute() % 5)
            .seconds(0)
            .millisecond(0));
    //return 1572382800 * 1000;
}

export const getWholeIntervalOfDatesToRequest = (): Interval => ({
    s: momentToNumber(moment().subtract(1, 'day').startOf('day')) / 1000,
    f: momentToNumber(moment().add(2, 'day').startOf('day')) / 1000
    // s: momentToNumber(moment(1572462900 * 1000).subtract(1, 'day').startOf('day')) / 1000,
    // f: momentToNumber(moment(1572462900 * 1000).add(2, 'day').startOf('day')) / 1000
});

export const getIntervalOfLast5MinutesDatesToRequest = (): Interval => {
    const previous5minutesIntervalStamp = getCurrentTimestamp() / 1000;

    return {
        s: previous5minutesIntervalStamp,
        f: previous5minutesIntervalStamp + (5 * 60)
    };
}

export const getRemainingTimeToTomorrowInMs = (): number => {
    return momentToNumber(moment().add(1, 'day').startOf('day')) - momentToNumber(moment());
    //return 30000;
}
  