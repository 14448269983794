import * as React from 'react';
import { ValueTypeInfo, ValueTypeCode } from "../store/ValueTypesInfo/types";
import { AssetActualValue, ActualValue } from "../store/ActualValues/types";

export const nonExistingValueDisplayText = "--";
export const nonExistingUnitDisplayText = "";
export const nonExistingActualValueDisplayText = "--";

const tariffAssetId = 999;

export interface ValueAndUnit {
    value?: number,
    valueDescription?: string,
    unit?: string,
    displayDecimalPlaces?: number
}

export const getValueTypeIdFromCode = 
        (valueTypeCode: ValueTypeCode,
        valueTypesInfo?: ValueTypeInfo[]): number | undefined => {

    if (!valueTypesInfo) {
        return undefined;
    }
            
    const valueTypeFound = valueTypesInfo.find(v => v.valueTypeCode === valueTypeCode);
    return valueTypeFound ? valueTypeFound.valueTypeId : undefined;
}

export const getAssetActualValues = 
        (assetId: number, 
        actualValues?: AssetActualValue[]): ActualValue[] | undefined => {
    
    if (!actualValues) {
        return undefined;
    }
    
    const values = actualValues.find(av => av.assetId === assetId);
    return values ? values.actualValues : undefined;
}

export const getTariffAssetActualValues = 
        (actualValues?: AssetActualValue[]): ActualValue[] | undefined =>     
    getAssetActualValues(tariffAssetId, actualValues);


export const getActualValueAndUnit = 
        (valueTypeCode: ValueTypeCode,
        actualValues?: ActualValue[],
        valueTypesInfo?: ValueTypeInfo[]): ValueAndUnit | undefined => {

    if (!valueTypesInfo || !actualValues) {
        return undefined;
    }
    
    const valueType = valueTypesInfo.find(vt => vt.valueTypeCode === valueTypeCode);
    if (!valueType) {
        return undefined;
    }

    const actualValue = actualValues.find(av => av.valueTypeId === valueType.valueTypeId);
    if (!actualValue) {
        return undefined;
    }

    return {
        value: actualValue.value,
        valueDescription: actualValue.valueDescription,
        unit: valueType.unit,
        displayDecimalPlaces: valueType.displayDecimalPlaces
    }
}

export const getActualValueAndUnitByValueTypeId = 
        (valueTypeId: number,
        actualValues?: ActualValue[],
        valueTypesInfo?: ValueTypeInfo[]): ValueAndUnit | undefined => {

    if (!valueTypesInfo || !actualValues) {
        return undefined;
    }
    
    const valueType = valueTypesInfo.find(vt => vt.valueTypeId === valueTypeId);
    if (!valueType) {
        return undefined;
    }

    const actualValue = actualValues.find(av => av.valueTypeId === valueType.valueTypeId);
    if (!actualValue) {
        return undefined;
    }

    return {
        value: actualValue.value,
        valueDescription: actualValue.valueDescription,
        unit: valueType.unit,
        displayDecimalPlaces: valueType.displayDecimalPlaces
    }
}

export const getMaxActualValueTimeStamp = 
        (actualValues: ActualValue[]): number | undefined => {

    let maxTimeStamp: number | undefined = undefined;

    actualValues.forEach(av => {
        if (av.timestamp && av.timestamp > (maxTimeStamp || 0)) {
            maxTimeStamp = av.timestamp;    
        }
    });

    return maxTimeStamp;
}


export const getSumOfActualValueAndUnit = 
        (assetIds: number[], 
        valueTypeCode: ValueTypeCode,
        valueTypesInfo?: ValueTypeInfo[], 
        actualValues?: AssetActualValue[]): ValueAndUnit | undefined => {

    if (!valueTypesInfo || !actualValues) {
        return undefined;
    }
    
    const valueType = valueTypesInfo.find(vt => vt.valueTypeCode === valueTypeCode);
    if (!valueType) {
        return undefined;
    }

    let valueAndUnit: ValueAndUnit = {
        value: undefined,
        valueDescription: undefined,
        unit: valueType.unit,
        displayDecimalPlaces: valueType.displayDecimalPlaces
    }

    assetIds.forEach(assetId => {
        const assetValues = actualValues.find(av => av.assetId === assetId);
        if (!assetValues) {
            return undefined;
        }
    
        const actualValue = assetValues.actualValues.find(av => av.valueTypeId === valueType.valueTypeId);
        if (!actualValue) {
            return undefined;
        }

        valueAndUnit.value = valueAndUnit.value !== undefined ? 
            (valueAndUnit.value + (actualValue.value || 0)) :
            actualValue.value;
    });

    return valueAndUnit;
}

const calculatePercentage = (actualValue?: number, maxPossibleValue?: number): number => {
    return (actualValue && maxPossibleValue && maxPossibleValue !== 0 && actualValue !== 0) ?
        (
            actualValue > maxPossibleValue ? 
            100 : 
            actualValue * 100 / maxPossibleValue
        ) :
        0;
}

export const getPercentageForValueAndUnits = 
        (partialValueAndUnit?: ValueAndUnit, totalValueAndUnit?: ValueAndUnit): number => {

    let percentage = (partialValueAndUnit && totalValueAndUnit) ?
        calculatePercentage(partialValueAndUnit.value, totalValueAndUnit.value) :
        0;
    
    return percentage >= 0 ? percentage : 0;
}

export const getEquipsOkPercentage = 
        (assetIds: number[], 
        valueTypesInfo?: ValueTypeInfo[], 
        actualValues?: AssetActualValue[]): number => {

        const equipsOk = getSumOfActualValueAndUnit(assetIds, "Asset.EquipmentsOK", valueTypesInfo, actualValues);
        if (equipsOk && equipsOk.value !== undefined && equipsOk.value > 0) {
            const equipsTotal = getSumOfActualValueAndUnit(assetIds, "Asset.EquipmentsTotal", valueTypesInfo, actualValues);
            if (equipsTotal) {
                return calculatePercentage(equipsOk.value, equipsTotal.value);
            }
        }

        return 0;
}


// formatting functions

export const formatNumericValueToDisplay = 
        (valueAndUnit?: ValueAndUnit): string => {

    if (valueAndUnit) {
        if (valueAndUnit.valueDescription !== undefined) {
            return valueAndUnit.valueDescription;
        }

        return (valueAndUnit.value !== undefined && valueAndUnit.value !== null) ? 
            (valueAndUnit.displayDecimalPlaces !== undefined ? valueAndUnit.value.toFixed(valueAndUnit.displayDecimalPlaces) : valueAndUnit.value.toString()) : 
            nonExistingValueDisplayText;
    }
    return nonExistingValueDisplayText;
}

export const formatUnitToDisplay = (valueAndUnit?: ValueAndUnit): string => {
    return valueAndUnit && valueAndUnit.unit ? valueAndUnit.unit : nonExistingUnitDisplayText;
}

export const buildValueAndUnitJSXElement = 
        (jsxElement: JSX.Element, valueAndUnit?: ValueAndUnit | boolean): JSX.Element => {

    if (valueAndUnit) {
        return jsxElement;
    }

    const element = React.createElement("span", null, nonExistingActualValueDisplayText);
    return element;
}