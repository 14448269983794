import * as React from 'react';
import { Route, Redirect } from 'react-router';
import { connect } from 'react-redux';
import * as userSelectors  from '../../store/User/reselectors';
import { Store } from '../../helpers/types';

interface ComponentProps {
	isUserLoggedin: boolean;
}

class AuthenticatedRouteComponent extends React.Component<any, any> {
	render() {
		const { isUserLoggedin, component: Component, ...rest } = this.props;
		return (
			<Route
				{...rest}
				render={(props) =>
					isUserLoggedin ? (
						<Component {...props} />
					) : (
						<Redirect
							to={{
								pathname: '/login/',
								state: { from: props.location }
							}}
						/>
					)}
			/>
		);
	}
}

export const AuthenticatedRoute = connect((state: Store): ComponentProps => {
	return {
		isUserLoggedin: userSelectors.isUserLoggedin(state),
	};
})(AuthenticatedRouteComponent);