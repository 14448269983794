import * as React from 'react';

import { FormattedMessageHelper } from '../../helpers/internationalization';

// tslint:disable-next-line:no-import-side-effect
import './ErrorBoundary.scss';

interface ErrorMessageProps {
  isNotFullScreen: boolean;
}

export const ErrorMessage = ({isNotFullScreen}: ErrorMessageProps) =>  (
  <div className={`error-boundary error-boundary${isNotFullScreen ? '--not-full' : ''}`}>
    <h1 className="error-boundary__header">
      <FormattedMessageHelper id="error.boundary.1" />
    </h1>
    <div className="error-boundary__content">
      <p className="error-boundary__element">
        <FormattedMessageHelper id="error.boundary.2" />
      </p>
      <p className="error-boundary__element">
        <FormattedMessageHelper id="error.boundary.3" />
      </p>
    </div>
  </div>
);
