import * as React from 'react';

import './DataLoadManager.scss';
import { FormattedMessageHelper } from '../../helpers/internationalization';

interface ComponentProps {
    isLoadingData: boolean,
    errorLoadingData: boolean
}

interface State {
}

export class DataLoadManager extends React.Component<ComponentProps, State> {

  render(): JSX.Element {

    return (

      <div className="dataloadmanager">
        {
        this.props.errorLoadingData ?
            <div className="dataloadmanager__error-message"><FormattedMessageHelper id="error.message" /></div>
        :
        (
            this.props.isLoadingData ?
            <div className="dataloadmanager__loading-message"><FormattedMessageHelper id="loadingdata.message" /></div>
            :
            this.props.children
        )
        }
      </div>
    );
  }
}
