import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { ErrorMessage } from './ErrorMessage';

interface ErrorBoundaryState {
  hasError: boolean;
}

interface ErrorBoundaryProps {
  showUI?: boolean;
  isNotFullScreen?: boolean;
  hideElement?: boolean;
  isModal?: any;
}

type ErrorBoundaryPropsGeneric = ErrorBoundaryProps & RouteComponentProps;

class ErrorBoundary extends React.Component<ErrorBoundaryPropsGeneric, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryPropsGeneric) {
    super(props);
    this.state = { hasError: false };
    const history = this.props.history;
    history.listen(() => {
      if (this.state.hasError) {
        this.setState({
          hasError: false,
        });
      }
    });
  }

  componentDidUpdate(prevProps: ErrorBoundaryPropsGeneric) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState({
        hasError: false,
      });
    }
  }

  componentDidCatch() {
    this.setState({ hasError: true });
  }

  render() {

    if (this.state.hasError && this.props.showUI) {
      return (
        <ErrorMessage
          isNotFullScreen={this.props.isNotFullScreen ? true : false}
        />
      );
    } else if (this.state.hasError && !this.props.showUI && this.props.hideElement) {
      return null;
    }

    return this.props.children;

  }
}

export default withRouter(ErrorBoundary);
