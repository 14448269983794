import { Component } from 'react';

class ScrollToTop extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';
    }
  }

  render() {
    return null;
  }
}

export default ScrollToTop;
