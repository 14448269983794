import { Dispatch } from "redux";

import { Api } from "../../lib/API";
import * as ClientsAssetsActions from './action';
import * as UserActions from '../User/action';
import { GetClientsAssetsInfoResult } from "./types";


const loadClientAssetsInfo = (token: string) => async (dispatch : Dispatch) => {

    dispatch(ClientsAssetsActions.loadClientsAssetsInfoRequest({}));
    const response = await Api.AUTHENTICATED_GET<GetClientsAssetsInfoResult, any>('api/v1/config/clientsassets', token);

    if (response.type === "auth_error") 
    {
        dispatch(UserActions.userLogoutSuccess());
    }
    else if (response.type === "error" || response.type === "server_error")
    {           
        dispatch(ClientsAssetsActions.loadClientsAssetsInfoFailure({}));
    }
    else
    {
        dispatch(ClientsAssetsActions.loadClientsAssetsInfoSuccess(response.result));
    }
}

const changeAssetState = (assetId:number, checked:boolean) => async (dispatch : Dispatch) => {
    dispatch(ClientsAssetsActions.changeAssetState({
        objId: assetId,
        newCheckedState: checked
    }));
}

const changeClientState = (clientId:number, checked:boolean) => async (dispatch : Dispatch) => {
    dispatch(ClientsAssetsActions.changeClientState({
        objId: clientId,
        newCheckedState: checked
    }));
}

const changeClientDetailsVisibility = (clientId:number, visible:boolean) => async (dispatch : Dispatch) => {
    dispatch(ClientsAssetsActions.changeClientDetailsVisibility({
        clientId: clientId,
        visible: visible
    }));
}

const selectAssetExclusively = (assetId:number) => async (dispatch : Dispatch) => {
    dispatch(ClientsAssetsActions.selectAssetExclusively({
        assetId: assetId
    }));
}

const resetToPreviousAssetsSelection = () => async (dispatch : Dispatch) => {
    dispatch(ClientsAssetsActions.resetToPreviousAssetsSelection());
}


const operations = {
    loadClientAssetsInfo,
    changeAssetState,
    changeClientState,
    changeClientDetailsVisibility,
    selectAssetExclusively,
    resetToPreviousAssetsSelection
};

export default operations;