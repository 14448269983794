import * as React from 'react';

import { Title } from '../Title';

import './ClientsList.scss';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Store } from '../../helpers/types';
import * as clientAndAssetsSelectors  from '../../store/ClientsAssetsInfo/selectors';
import * as valueTypesInfoSelectors  from '../../store/ValueTypesInfo/selectors';
import * as actualValuesSelectors  from '../../store/ActualValues/selectors';
import { ClientsAssetsState } from '../../store/ClientsAssetsInfo/types';
import ClientsListItem from '../ClientsListItem/ClientsListItem';
import navOperations from '../../store/Navigation/operations';
import { FormattedMessageHelper } from '../../helpers/internationalization';
import { DataLoadManager } from '../DataLoadManager/DataLoadManager';
import { LoadDataInfo } from '../../store/types';

interface ComponentProps {
  clientAndAssets?: ClientsAssetsState,
  valueTypesLoadInfo?: LoadDataInfo,
  actualValuesLoadInfo?: LoadDataInfo,
  dispatchCollapse?: any
}

interface State {
}

class ClientsListComponent extends React.Component<ComponentProps, State> {

  handleCollapseClick = (event: any) => {
    this.props.dispatchCollapse();
  };

  render(): JSX.Element {
    const isLoadingData = (this.props.clientAndAssets !== undefined && this.props.clientAndAssets.loadDataInfo.isLoadingData) ||
      (this.props.valueTypesLoadInfo !== undefined && this.props.valueTypesLoadInfo.isLoadingData) ||
      (this.props.actualValuesLoadInfo !== undefined && this.props.actualValuesLoadInfo.isLoadingData);

    const errorLoadingData = (this.props.clientAndAssets !== undefined && this.props.clientAndAssets.loadDataInfo.errorLoadingData) ||
      (this.props.valueTypesLoadInfo !== undefined && this.props.valueTypesLoadInfo.errorLoadingData) ||
      (this.props.actualValuesLoadInfo !== undefined && this.props.actualValuesLoadInfo.errorLoadingData);

    const clientsList = this.props.clientAndAssets !== undefined ? this.props.clientAndAssets.clientsState : [];

    return (

      <div className="clients">
        <Title title="client.title" showCollapseAction={true} handleCollapseClick={this.handleCollapseClick} />

        <DataLoadManager isLoadingData={isLoadingData} errorLoadingData={errorLoadingData}>
          {
          clientsList.length > 0 ?
            clientsList.map(c =>
              <ClientsListItem 
                key={c.clientInfo.clientId}
                clientId={c.clientInfo.clientId} />
              )
            :
            <div className="dashboard__nodata-message"><FormattedMessageHelper id="client.nodatamessage" /></div>
          }
        </DataLoadManager>
      </div>
    );
  }
}

const mapStateToProps = (state: Store): any => ({
  clientAndAssets: clientAndAssetsSelectors.getClientsAndAssets(state),
  valueTypesLoadInfo: valueTypesInfoSelectors.getLoadInfo(state),
  actualValuesLoadInfo: actualValuesSelectors.getLoadInfo(state)
});

const mapDispatchToProps = (dispatch: Dispatch<any>): any => ({
  dispatchCollapse: () => dispatch(navOperations.collapseLeftAreaView())
});

const ClientsList = connect(mapStateToProps, mapDispatchToProps)(ClientsListComponent)

export default ClientsList;
