import { createSelector } from 'reselect';

import { Store } from '../../helpers/types';

export const timelineDataReducerSelector = (state: Store) => state.timelineData;

export const getLoadInfo = createSelector(
  timelineDataReducerSelector,
  (timelineData) => {
      return timelineData.loadDataInfo;
  } 
);

export const timelineDataSelector = createSelector(
  timelineDataReducerSelector,
  (timelineData) => timelineData
);

export const dataPointsSelector = createSelector(
  timelineDataReducerSelector,
  (timelineData) => timelineData.dataPoints
);

export const currentTimestampSelector = createSelector(
  timelineDataReducerSelector,
  (timelineData) => timelineData.currentTimestamp
);

export const currentFullIntervalSelector = createSelector(
  timelineDataReducerSelector,
  (timelineData) => timelineData.currentFullInterval
);

export const chartTimestampsSelector = createSelector(
  timelineDataReducerSelector,
  (timelineData) => timelineData.chartTimestamps
);

export const missingDataIntervalsSelector = createSelector(
  timelineDataReducerSelector,
  (timelineData) => timelineData.missingDataIntervals
);

export const batchesSelector = createSelector(
  timelineDataReducerSelector,
  (timelineData) => timelineData.batches
);
