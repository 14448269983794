import React from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import './assets/stylesheets/index.scss';
import App from './components/App/App';
import { localeData } from './locales/data';
import * as serviceWorker from './serviceWorker';
import { combinedStore } from './store';

const locale = (navigator.languages && navigator.languages[0]) ||
               navigator.language ||
               'pt-PT';

const language = locale.substring(0, locale.indexOf('-'));
//console.log("language", language);

const getMessages = (localeDataObject: any, languageCodeObject = false, localeObject = false) => {
  if (!localeDataObject) {
    return undefined;
  } else if (languageCodeObject) {
    return languageCodeObject;
  } else if (localeObject) {
    return localeObject;
  }

  return localeDataObject.pt;
};

const messages = getMessages(localeData, localeData[language]);

const store = createStore(
  combinedStore,
  composeWithDevTools(
    applyMiddleware(
      thunk,
    ),
  ),
);

ReactDOM.render(
 // tslint:disable-next-line:jsx-wrap-multiline
  <IntlProvider locale={locale} messages={messages}>
    <Router>
      <Provider store={store}>
        <App />
      </Provider>
    </Router>
  </IntlProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// Unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
