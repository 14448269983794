import * as Moment from "moment";
import mTZ from 'moment-timezone';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import * as React from 'react';
import { connect } from 'react-redux';

// tslint:disable-next-line: no-import-side-effect
import '../../config/configHighchart.ts';
import { FormattedMessageHelper } from '../../helpers/internationalization';
import { Store, InjectedIntlProps } from '../../helpers/types';
import  * as timelineSelectors  from '../../store/TimelineData/reselectors';
import  * as navSelectors  from '../../store/Navigation/selectors';
import  * as clientAssetsSelectors  from '../../store/ClientsAssetsInfo/selectors';
import  * as valueTypesInfoSelectors  from '../../store/ValueTypesInfo/selectors';
import { ErrorBoundary } from '../ErrorBoundary';
import { Title } from '../Title';

import './Chart.scss';
import { options } from './ChartConfig';
import { NavigationState } from '../../store/Navigation/types';
import { DataPoint, TimelineDataState } from '../../store/TimelineData/types';
import * as valueTypesHelper from '../../helpers/valuetypeshelper';
import * as dateHelper from '../../helpers/datehelper';
import { ValueTypeInfo } from '../../store/ValueTypesInfo/types';
import DataAvailability from '../DataAvailability/DataAvailability';
import { LoadDataInfo } from '../../store/types';
import { DataLoadManager } from '../DataLoadManager/DataLoadManager';
import {injectIntl} from 'react-intl';


interface ComponentProps {
  currentTimestamp?: number,
  clientAndAssetsLoadInfo?: LoadDataInfo,
  valueTypesLoadInfo?: LoadDataInfo,
  valueTypesInfo?: ValueTypeInfo[],
  timelineData?: TimelineDataState,
  navigationState?: NavigationState,
  selectedAssets?: { assetId: number, assetName: string }[]
}

interface State {
  internalChart: any,
  initialWidth: number,
  initialHeight: number,
}

interface ChartData {
  activePowerData: {
    [timestamp: number]: number | null
  },
  activePowerForecastData: {
    [timestamp: number]: number | null
  },
  tariffData: {
    [timestamp: number]: number | null
  }
}

declare global {
  interface Window {
    moment: any;
  }
}
window.moment = Moment;
mTZ();

type ComponentGenericProps = ComponentProps & InjectedIntlProps;

class ChartComponent extends React.Component<ComponentGenericProps, State> {

  constructor(props: ComponentGenericProps) {
    super(props);

    //console.log("constructor");
    this.state = {
      internalChart: null,
      initialHeight: 0,
      initialWidth: 0,
    };

    this.afterChartCreated = this.afterChartCreated.bind(this);
  }

  hasDataToChart = ():boolean => this.props.timelineData !== undefined && this.props.timelineData.dataPoints.length > 0;

  // Formats data to be consumed by hightchart
  // E.g. {timestamp_1: value_1, timestamp_2: value_2, ...} > [[timestamp_1, value_1], [timestamp_2, value_2], ...]
  formatChartDataForHighchart = (chartData: any) => {
    if (!chartData) {
      return;
    }
    const hightchartData: any = [];
    Object.keys(chartData).forEach((dataKey: any) => {
      hightchartData.push([Number(dataKey), chartData[dataKey]]);
    });

    return hightchartData;
  };
  // Formats data to be consumed by hightchart (range area)
  // E.g. (2 objects) > [[timestamp_1, value_1a, value_1b], [timestamp_2, value_2a, value_2b], ...]
  formatRangeChartDataForHighchart = (firstChartData: any, secondChartData: any) => {
    if (!firstChartData && !secondChartData) {
      return;
    }
    const hightchartData: any = [];
    Object.keys(firstChartData).forEach((dataKey: any) => {
      hightchartData.push([Number(dataKey), firstChartData[dataKey], secondChartData[dataKey]]);
    });

    return hightchartData;
  };



  //static getDerivedStateFromProps: (props: ComponentProps, state: State) => {
  setupChartData(): ChartData {

    //console.log("setupChartData");
    const activePowerValueTypeId = valueTypesHelper.getValueTypeIdFromCode(
      "Chart.ActivePower", 
      this.props.valueTypesInfo);
    const activePowerForecastValueTypeId = valueTypesHelper.getValueTypeIdFromCode(
      "Chart.ActivePowerForecast", 
      this.props.valueTypesInfo);
    const tariffValueTypeId = valueTypesHelper.getValueTypeIdFromCode(
      "Global.Tariff", 
      this.props.valueTypesInfo);
    const tariffForecastValueTypeId = valueTypesHelper.getValueTypeIdFromCode(
      "Global.TariffForecast", 
      this.props.valueTypesInfo);
  
    const selectedAssets = this.props.selectedAssets || [];
    const currentTimestamp = this.props.currentTimestamp || dateHelper.getCurrentTimestamp();

    let result: ChartData = {
      activePowerData: {},
      activePowerForecastData: {},
      tariffData: {}
    };

    if (activePowerValueTypeId && activePowerForecastValueTypeId && tariffValueTypeId && tariffForecastValueTypeId &&
        this.props.timelineData && 
        this.props.timelineData.dataPoints.length > 0 && 
        selectedAssets.length > 0) {

        //console.log("setupChartData - processing chart data");

      for (let timeIndex = this.props.timelineData.currentFullInterval.s; 
          timeIndex <= this.props.timelineData.currentFullInterval.f; 
          timeIndex = timeIndex + (5 * 60)) {

        const allDataPointsFound = this.props.timelineData.dataPoints.filter(d => d.timestamp === timeIndex && selectedAssets.findIndex(a => a.assetId === d.assetId) !== -1);
        const key = timeIndex * 1000;

        // Active Power
        const activePowerDataPoints = allDataPointsFound.filter(d => d.valueTypeId === activePowerValueTypeId);
        if (activePowerDataPoints.length === 0) {
          result.activePowerData[key] = null;
        }
        else {
          activePowerDataPoints.forEach((asset: DataPoint) => {
              if (!result.activePowerData[key]) {
                result.activePowerData[key] = asset.value;
              } else {
                result.activePowerData[key] = (result.activePowerData[key] || 0) + asset.value;
              }
            });  
        }

        // Active Power Forecast
        const activePowerForecastDataPoints = allDataPointsFound.filter(d => d.valueTypeId === activePowerForecastValueTypeId);
        if (activePowerForecastDataPoints.length > 0) {
          activePowerForecastDataPoints.forEach((asset: DataPoint) => {
              if (!result.activePowerForecastData[key]) {
                result.activePowerForecastData[key] = asset.value;
              } else {
                result.activePowerForecastData[key] = (result.activePowerForecastData[key] || 0) + asset.value;
              }
            });
        }

        // Tariff
        const tariffDataPoints = key <= currentTimestamp ?
                allDataPointsFound.filter(d => d.valueTypeId === tariffValueTypeId) :
                allDataPointsFound.filter(d => d.valueTypeId === tariffForecastValueTypeId);

        if (tariffDataPoints.length !== 0) {
            tariffDataPoints.forEach((asset: DataPoint) => {
              if (!result.tariffData[key]) {
                result.tariffData[key] = asset.value;
              } else {
                result.tariffData[key] = (result.tariffData[key] || 0) + asset.value;
              }
            });  
        }
      }
    }

    return result;
  }


  afterChartCreated(chart: any) {
    //console.log("chart", chart);
    //console.log("chart size", chart.spacingBox.width, chart.spacingBox.height);
    this.setState({
      internalChart: chart,
      initialHeight: chart.spacingBox.height,
      initialWidth: chart.spacingBox.width,
    });
  }

  setSize() {
    // console.log("setSize", this.state.internalChart);
    if (this.state.internalChart) {
      this.state.internalChart.setSize(null, null);
    }
  }

  componentDidUpdate(prevProps: any) {
    const prevLeftAreaCollapsed: boolean = prevProps.navigationState !== undefined && prevProps.navigationState.leftAreaCollapsed;
    const leftAreaCollapsed: boolean = this.props.navigationState !== undefined && this.props.navigationState.leftAreaCollapsed;

    if (prevLeftAreaCollapsed !== leftAreaCollapsed) {
      this.setSize();
    }
  }

  render():JSX.Element {
    // console.log("Chart render");
    //console.log("this.internalChart", this.state.internalChart);
    const isLoadingData = (this.props.clientAndAssetsLoadInfo !== undefined && this.props.clientAndAssetsLoadInfo.isLoadingData) ||
      (this.props.valueTypesLoadInfo !== undefined && this.props.valueTypesLoadInfo.isLoadingData) ||
      (this.props.timelineData !== undefined && this.props.timelineData.loadDataInfo.isLoadingData);

    const errorLoadingData = (this.props.clientAndAssetsLoadInfo !== undefined && this.props.clientAndAssetsLoadInfo.errorLoadingData) ||
      (this.props.valueTypesLoadInfo !== undefined && this.props.valueTypesLoadInfo.errorLoadingData) ||
      (this.props.timelineData !== undefined && this.props.timelineData.loadDataInfo.errorLoadingData);

    const leftAreaCollapsed: boolean = this.props.navigationState !== undefined && this.props.navigationState.leftAreaCollapsed;

    const chartData = this.setupChartData();
    const activePowerData = chartData.activePowerData;
    const activePowerForecastData = chartData.activePowerForecastData;
    const tariffData = chartData.tariffData;
    let currentTimestamp = this.props.currentTimestamp || dateHelper.getCurrentTimestamp();

    const chartWrapperClassNames = "chart__wrapper " + (leftAreaCollapsed ? "chart__wrapper__fullsize" : "chart__wrapper__normalsize");
    const chartStyle = {
      width: '100%',
      height: '100%'
    };

    return (
      <ErrorBoundary showUI={true}>
        <div>
          <Title title="chart.title"/>

          <DataLoadManager isLoadingData={isLoadingData} errorLoadingData={errorLoadingData}>
            { this.hasDataToChart() ?
            <div>
              <div className={chartWrapperClassNames}>
                <HighchartsReact
                  highcharts={Highcharts}
                  containerProps={{style: chartStyle}}
                  options={options(
                    this.formatChartDataForHighchart(tariffData),
                    this.formatRangeChartDataForHighchart(activePowerData, activePowerForecastData),
                    this.formatChartDataForHighchart(activePowerData),
                    this.formatChartDataForHighchart(activePowerForecastData),
                    currentTimestamp,
                    currentTimestamp,
                    this.props.intl
                  )}
                  callback={ this.afterChartCreated }
                  constructorType="stockChart"
                />
              </div>

              <DataAvailability />

              <div className="chart__legend">
                <div className="chart__legend--energy">
                  <span><FormattedMessageHelper id="chart.legend.energyproduction" /></span>
                </div>
                <div className="chart__legend--energy-predicted">
                  <span><FormattedMessageHelper id="chart.legend.energypredicted" /></span>
                </div>
                <div className="chart__legend--fare">
                  <span><FormattedMessageHelper id="chart.legend.fare" /></span>
                </div>
                <div className="chart__legend--fare-expected">
                  <span><FormattedMessageHelper id="chart.legend.fareexpected" /></span>
                </div>
              </div>
            </div>
            :
            <div className="dashboard__nodata-message"><FormattedMessageHelper id="chart.nodatamessage" /></div>
            }
          </DataLoadManager>
        </div>
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = (state: Store): any => ({
  clientAndAssetsLoadInfo: clientAssetsSelectors.getLoadInfo(state),
  valueTypesLoadInfo: valueTypesInfoSelectors.getLoadInfo(state),
  timelineData: timelineSelectors.timelineDataSelector(state),
  currentTimestamp: timelineSelectors.currentTimestampSelector(state),
  valueTypesInfo: valueTypesInfoSelectors.getValueTypes(state),
  navigationState: navSelectors.getNavigationState(state),
  selectedAssets: clientAssetsSelectors.getSelectedAssets(state),
});




const Chart = connect(mapStateToProps)(injectIntl(ChartComponent))

export default Chart;
