import * as React from 'react';

import  './WarningsListItem.scss';
import { FormattedTime } from 'react-intl';

export interface WarningProps {
    key: string,
    message: string,
    date?: Date,
    warningColor?: string
}


const WarningsListItem = (props: WarningProps): JSX.Element => {

    const iconStyle = {
        backgroundColor: props.warningColor ? props.warningColor : "#FFFFFF"
    }

    return (
        <li className="alerts__item">
            <div className="alerts__icon" style={iconStyle} />
            <div className="alerts__desc">{props.message}</div>
            <div className="alerts__time">
                {props.date &&
                <FormattedTime value={props.date} year="numeric" month="2-digit" day="2-digit" 
                    hour='numeric' minute='numeric' second='numeric' />
                }
            </div>
        </li>
)};

export default WarningsListItem;
