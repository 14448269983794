import * as React from 'react';

import { ErrorBoundary } from '../ErrorBoundary';

import  './Login.scss';
import { connect } from 'react-redux';
import { Store } from '../../helpers/types';
import { Dispatch } from 'redux';
import * as userSelectors  from '../../store/User/reselectors';
import userOperations from '../../store/User/operations';
import { Redirect } from 'react-router-dom';
import { FormattedMessageHelper } from '../../helpers/internationalization';

interface ComponentProps {
    isUserLoggedin?: boolean,
    hasLoginFailed?: boolean,
    errorOnLogin?: boolean,
    isWaitingForLoginResponse?: boolean,
    dispatchLoginSubmit?: any,
}

interface State {
    username: string,
    password: string
}

class LoginComponent extends React.Component<ComponentProps, State> {

    constructor(props: ComponentProps) {
        super(props);

        this.state = {
            username: "",
            password: ""
        };

        this.handleChangeUsername = this.handleChangeUsername.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);
    }

    handleSubmit = (event: any) => {
        this.props.dispatchLoginSubmit(this.state.username, this.state.password);

        event.preventDefault();
    };

    handleChangeUsername(event: any) {
        this.setState({username: event.target.value});
    }

    handleChangePassword(event: any) {
        this.setState({password: event.target.value});
    }

    isFormValid() {
        return this.state.username.trim() !== "" && this.state.password.trim() !== "";
    }

    render(): JSX.Element {
        if (this.props.isUserLoggedin)
		{
            return (<Redirect to="/" />)
		}

        return (
            <ErrorBoundary showUI={true}>
                <div className="login__area">
                    <div className="login__area-logo">
                        <img src="/dynamic/media/logo.svg" alt="Logo"/>
                    </div>
                    <div className="login__area-title">
                        <FormattedMessageHelper id="login.title" />
                    </div>
                    <div className="login__area-form">
                        <form className="login__form" onSubmit={this.handleSubmit}>
                            <div className="login__area-form-field">
                                <div className="login__area-form-field-label">
                                    <FormattedMessageHelper id="login.fields.username" />
                                </div>
                                <div>
                                    <input className="input-text" type="text" id="username" 
                                        disabled={this.props.isWaitingForLoginResponse}
                                        value={this.state.username} 
                                        onChange={this.handleChangeUsername} />
                                </div>
                            </div>
                            <div className="login__area-form-field">
                                <div className="login__area-form-field-label">
                                    <FormattedMessageHelper id="login.fields.password" />
                                </div>
                                <div>
                                    <input className="input-text" type="password" id="password" 
                                        disabled={this.props.isWaitingForLoginResponse}
                                        value={this.state.password} 
                                        onChange={this.handleChangePassword} />
                                </div>
                            </div>
                            <div className="login__area-form-button">
                                { this.props.isWaitingForLoginResponse ?
                                <div className="waitingmessage"><FormattedMessageHelper id="login.waiting" /></div>
                                :
                                <button id="submitbutton" type="submit" disabled={!this.isFormValid()}>
                                    <FormattedMessageHelper id="login.button" />
                                </button>
                                }
                            </div>
                            <div className="login__area-validationmessage">
                            { this.props.hasLoginFailed &&
                                <FormattedMessageHelper id="login.failed" />
                            }
                            { this.props.errorOnLogin &&
                                <span className="login__area-errormessage">
                                    <FormattedMessageHelper id="error.message" />
                                </span>
                            }
                            </div>
                        </form>
                    </div>
                </div>
            </ErrorBoundary>
        )
    }
}

const mapStateToProps = (state: Store): any => ({
    isUserLoggedin: userSelectors.isUserLoggedin(state),
    hasLoginFailed: userSelectors.hasLoginFailed(state),
    errorOnLogin: userSelectors.errorOnLogin(state),
    isWaitingForLoginResponse: userSelectors.isWaitingForLoginResponse(state),
});

const mapDispatchToProps = (dispatch: Dispatch<any>): any => ({
    dispatchLoginSubmit: (username: string, password: string) => dispatch(userOperations.login({username: username, password: password})),
});
  
const Login = connect(mapStateToProps, mapDispatchToProps)(LoginComponent)

export default Login;
