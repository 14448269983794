import * as React from 'react';

import './Header.scss';
import { connect } from 'react-redux';
import * as userSelectors  from '../../store/User/reselectors';
import userOperations from '../../store/User/operations';
import { Dispatch } from 'redux';
import { Store } from '../../helpers/types';

interface ComponentProps {
  isUserLoggedin?: boolean,
  token?: string,
  dispatchLogout?: any,
}

interface State {
  username: string,
  password: string
}

class HeaderComponent extends React.Component<ComponentProps, State> {

  handleLogoutClick = (event: any) => {
    //console.log( "handleLogoutClick");
    this.props.dispatchLogout(this.props.token);
  };

  render(): JSX.Element {
      return (
        <div className="header">
          <h1 className="header__logo">WHS</h1>
          { this.props.isUserLoggedin &&
            <div className="header__user" onClick={this.handleLogoutClick} />
          }
        </div>
      );
    }
  }

const mapStateToProps = (state: Store): any => ({
  isUserLoggedin: userSelectors.isUserLoggedin(state),
  token: userSelectors.getToken(state),
});

const mapDispatchToProps = (dispatch: Dispatch<any>): any => ({
  dispatchLogout: (token: string) => dispatch(userOperations.logout(token)),
});

const Header = connect(mapStateToProps, mapDispatchToProps)(HeaderComponent)

export default Header;
