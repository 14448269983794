import { handleActions } from 'redux-actions';
import { LoadValueTypesInfoActionType, ValueTypesInfoState } from './types';

const initialState: ValueTypesInfoState = {
  loadDataInfo: {
    isLoadingData: true,
    errorLoadingData: false
  },
  info: [],
  lookups: []
};

const ValueTypesInfoReducer = handleActions<any>(
  {
    USER_LOGOUT: (state: any, action: any) => {
      return initialState;
    },

    LOAD_VALUE_TYPES_INFO_REQUEST: (state: any, action: LoadValueTypesInfoActionType) => {
      const newState: ValueTypesInfoState = {
        ...state,
        loadDataInfo: {
          isLoadingData: true,
          errorLoadingData: false
        },
      };

      return newState;
    },

    LOAD_VALUE_TYPES_INFO_FAILURE: (state: any, action: LoadValueTypesInfoActionType) => {
      const newState: ValueTypesInfoState = {
        ...state,
        loadDataInfo: {
          isLoadingData: false,
          errorLoadingData: true
        },
      };

      return newState;
    },

    LOAD_VALUE_TYPES_INFO_SUCCESS: (state: any, action: LoadValueTypesInfoActionType) => {
      const newState: ValueTypesInfoState = {
        loadDataInfo: {
          isLoadingData: false,
          errorLoadingData: false
        },
        info: [...action.payload.valueTypes],
        lookups: [...action.payload.lookups]
      };

      return newState;
    },

  },
  initialState,
);

export default ValueTypesInfoReducer;
