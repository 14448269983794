import { createActions } from 'redux-actions';

export const {
  userLogout,
  loadValueTypesInfoRequest,
  loadValueTypesInfoFailure,
  loadValueTypesInfoSuccess
} = createActions(
  'USER_LOGOUT',
  'LOAD_VALUE_TYPES_INFO_REQUEST',
  'LOAD_VALUE_TYPES_INFO_FAILURE',
  'LOAD_VALUE_TYPES_INFO_SUCCESS'
);
