import { Store } from '../../helpers/types';
import { createSelector } from 'reselect';

export const clientsAssetsReducerSelector = (state: Store) => state.clientsAssetsState;

export const getLoadInfo = createSelector(
    clientsAssetsReducerSelector,
    (clientsAssetsState) => {
        return clientsAssetsState.loadDataInfo;
    } 
);

export const getSelectedAssets = createSelector(
    clientsAssetsReducerSelector,
    (clientsAssetsState) => {
        return clientsAssetsState.assetsState
            .filter(a => a.checked)
            .map(a => { return { assetId: a.assetInfo.assetId, assetName: a.assetInfo.assetName } })
    }
);

export const getSelectedAssetIdsWithClient = createSelector(
    clientsAssetsReducerSelector,
    (clientsAssetsState) => {
        return clientsAssetsState.assetsState
            .filter(a => a.checked && a.assetInfo.clientId)
            .map(a => { return a.assetInfo.assetId })
    }
);


export const getAssets = createSelector(
    clientsAssetsReducerSelector,
    (clientsAssetsState) => {
        //console.log("getAssets", clientsAssetsState.assetsState)
        return clientsAssetsState.assetsState
    } 
);

export const getAssetIds = createSelector(
    clientsAssetsReducerSelector,
    (clientsAssetsState) => {
        let assetIds: number[] = [];
        //console.log("getAssetIds", clientsAssetsState.assetsState);
        clientsAssetsState.assetsState.forEach(a => assetIds.push(a.assetInfo.assetId));
        return assetIds;
    } 
);


export const getClients = createSelector(
    clientsAssetsReducerSelector,
    (clientsAssetsState) => {
        //console.log("getClients", clientsAssetsState.clientsState)
        return clientsAssetsState.clientsState
    } 
);

export const getClientsAndAssets = createSelector(
    clientsAssetsReducerSelector,
    (clientsAssetsState) => {
        //console.log("getClientsAndAssets", clientsAssetsState)
        return clientsAssetsState
    } 
);

