import { handleActions } from 'redux-actions';
import { LoadAssetsActualValuesActionType, AssetActualValuesState } from './types';

const initialState: AssetActualValuesState = {
  loadDataInfo: {
    isLoadingData: true,
    errorLoadingData: false
  },
  values: []
};

const ActualValuesReducerType = handleActions<any>(
  {
    USER_LOGOUT: (state: any, action: any) => {
      return initialState;
    },

    LOAD_ASSETS_ACTUAL_VALUES_REQUEST: (state: any, action: LoadAssetsActualValuesActionType) => {
      return {
        ...state,
        loadDataInfo: {
          isLoadingData: true,
          errorLoadingData: false
        },
      }
    },

    LOAD_ASSETS_ACTUAL_VALUES_FAILURE: (state: any, action: LoadAssetsActualValuesActionType) => {
      return {
        ...state,
        loadDataInfo: {
          isLoadingData: false,
          errorLoadingData: true
        },
      }
    },

    LOAD_ASSETS_ACTUAL_VALUES_SUCCESS: (state: any, action: LoadAssetsActualValuesActionType) => {

      const newState: AssetActualValuesState = {
        loadDataInfo: {
          isLoadingData: false,
          errorLoadingData: false
        },
        values: [
          ...action.payload.actualValues.results
        ]
      }

      newState.values.forEach(a => {
        const actualValuesWithValues = a.actualValues.filter(av => av.value !== undefined);
        actualValuesWithValues.forEach(av => {
          const lookup = action.payload.valueTypeLookups.find(vtl => vtl.valueTypeId === av.valueTypeId && vtl.value === av.value);
          if (lookup) {
            av.valueDescription = lookup.description;
          }
        });
      })

      return newState;
    },

  },
  initialState,
);

export default ActualValuesReducerType;
