import * as React from 'react';

import  './LeftAreaCollapseExpand.scss';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Store } from '../../helpers/types';
import  * as selectors  from '../../store/Navigation/selectors';
import { NavigationState } from '../../store/Navigation/types';
import navOperations from '../../store/Navigation/operations';

import { ReactComponent as ShowIconSvgComponent } from "./images/show-icon.svg"
import { ReactComponent as HideIconSvgComponent } from "./images/hide-icon.svg"


interface ComponentProps {
    navigationState?: NavigationState,
    dispatchCollapse?: any,
    dispatchExpand?: any
}

interface State {
}

class LeftAreaCollapseExpandComponent extends React.Component<ComponentProps, State> {

    handleClick = (event: any) => {
        if (this.props.navigationState) {
            if (this.props.navigationState.leftAreaCollapsed) {
                this.props.dispatchExpand();
            }
            else {
                this.props.dispatchCollapse();
            }
        }
    };

    render(): JSX.Element {
        return(
            <div className="left-area-collapse-expand" onClick={this.handleClick} >
                { this.props.navigationState && 
                    (this.props.navigationState.leftAreaCollapsed ?
                        <ShowIconSvgComponent className="left-area-collapse-expand-icon" display="block" /> :
                        <HideIconSvgComponent className="left-area-collapse-expand-icon" display="block" />
                    )
                }
            </div>
        );
    }
}

const mapStateToProps = (state: Store): any => ({
    navigationState: selectors.getNavigationState(state)
  });

const mapDispatchToProps = (dispatch: Dispatch<any>): any => ({
    dispatchCollapse: () => dispatch(navOperations.collapseLeftAreaView()),
    dispatchExpand: () => dispatch(navOperations.expandLeftAreaView())
});

const LeftAreaCollapseExpand = connect(mapStateToProps, mapDispatchToProps)(LeftAreaCollapseExpandComponent)

export default LeftAreaCollapseExpand;
