import { createSelector } from 'reselect';

import { Store } from '../../helpers/types';

export const userSelector = (state: Store) => state.user;

export const getToken = createSelector(
  userSelector,
  (user) => localStorage.getItem('token')
);

export const isUserLoggedin = createSelector(
  userSelector,
  (user) => (user.loginStatus === 'LOGIN_SUCCESS' || user.loginStatus === 'LOGOUT_STARTED') && localStorage.getItem('token') !== null
);

export const hasLoginFailed = createSelector(
  userSelector,
  (user) => user.loginStatus === 'LOGIN_FAILED'
);

export const errorOnLogin = createSelector(
  userSelector,
  (user) => user.loginStatus === 'LOGIN_FAILURE'
);

export const isWaitingForLoginResponse = createSelector(
  userSelector,
  (user) => user.loginStatus === 'LOGIN_STARTED'
);
