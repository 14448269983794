import * as React from 'react';

import { Chart } from '../../components/Chart';
import { WarningsList } from '../../components/WarningsList';
import LeftArea from '../../components/LeftArea/LeftArea';
import { Store } from '../../helpers/types';
import { Dispatch } from 'redux';
import clientsAssetsOperations from '../../store/ClientsAssetsInfo/operations';
import valueTypeOperations from '../../store/ValueTypesInfo/operations';
import actualValuesOperations from '../../store/ActualValues/operations';
import timelineDataOperations from '../../store/TimelineData/operations';
import { connect } from 'react-redux';
import * as clientAssetsSelectors from '../../store/ClientsAssetsInfo/selectors';
import * as navSelectors  from '../../store/Navigation/selectors';
import * as valueTypesSelectors  from '../../store/ValueTypesInfo/selectors';
import * as timelineDataSelectors  from '../../store/TimelineData/reselectors';
import LeftAreaCollapseExpand from '../../components/LeftAreaCollapseExpand/LeftAreaCollapseExpand';
import { NavigationState } from '../../store/Navigation/types';
import { Interval } from '../../store/TimelineData/types';
import * as dateHelper from '../../helpers/datehelper';
import { ValueTypeLookup } from '../../store/ValueTypesInfo/types';
import { TimersConfig } from './timersconfig';
import * as userSelectors  from '../../store/User/reselectors';
import Header from '../../components/Header/Header';

import  './Dashboard.scss';

interface ComponentProps {
  dispatchLoadClientsAndAssets?: any,
  dispatchLoadValueTypes?: any,
  dispatchLoadActualValues?: any,
  dispatchSetCurrentFullInterval?: any,
  dispatchSetFullIntervalChartData?: any,
  dispatchSetPartialIntervalChartData?: any,
  token: string,
  batches: any[],
  assetIds: number[],
  numberAssetsWithNoMissingIntervals: number,
  navigationState?: NavigationState,
  valueTypesForTimeline?: number[],
  tariffValueTypeIds?: number[],
  activePowerValueTypeId?: number,
  valueTypeLookups?: ValueTypeLookup[],
  missingDataIntervalsSelector?: Interval[],
  forecastValueTypeId: number
}

interface State {
  timerToLoadActualValues: any,
  timerToLoadActivePowerForecastTimelineValues: any,
  timerToLoadActivePowerTimelineValues: any,
  timerToRefreshPage: any,
  timerToLoadTariffValues: any
}

class DashBoardPage extends React.Component<ComponentProps, State> {

  constructor(props: ComponentProps) {
    super(props);

    this.state = {
      timerToLoadActivePowerForecastTimelineValues: null,
      timerToLoadActivePowerTimelineValues: null,
      timerToLoadActualValues: null,
      timerToLoadTariffValues: null,
      timerToRefreshPage: null
    }
  }

  componentDidMount() {

    this.props.dispatchLoadClientsAndAssets(this.props.token).then(() => {
      if (this.props.assetIds.length > 0) {
        this.props.dispatchLoadValueTypes(this.props.token).then(() => {
          // load actual values ONLY AFTER getting Assets info
          this.props.dispatchLoadActualValues(this.props.token, this.props.assetIds, this.props.valueTypeLookups);

          if (this.props.activePowerValueTypeId === undefined ||
            !this.props.tariffValueTypeIds ||
            !this.props.valueTypesForTimeline) {
              return;
          }

           
          this.props.dispatchSetFullIntervalChartData(
            this.props.token,
            [],
            this.props.assetIds,
            this.props.numberAssetsWithNoMissingIntervals,
            this.props.valueTypesForTimeline, 
            dateHelper.getCurrentTimestamp(),
            dateHelper.getWholeIntervalOfDatesToRequest());
          
          //console.log("Ms to end of day", dateHelper.getRemainingTimeToTomorrowInMs());

          this.setState({
            timerToLoadActualValues: setInterval(async () => this.props.dispatchLoadActualValues(
            //timerToLoadActualValues: setTimeout(async () => this.props.dispatchLoadActualValues(
              this.props.token, this.props.assetIds, this.props.valueTypeLookups), 
              TimersConfig.actualValuesFrequencyInMs),

            timerToLoadActivePowerForecastTimelineValues: setInterval(async () => this.props.dispatchSetPartialIntervalChartData(
            //timerToLoadActivePowerTimelineValues: setTimeout(async () => this.props.dispatchSetPartialIntervalChartData(
              this.props.token,
              this.props.batches,
              this.props.assetIds,
              this.props.numberAssetsWithNoMissingIntervals,
              [this.props.forecastValueTypeId],
              dateHelper.getCurrentTimestamp(),
              [dateHelper.getWholeIntervalOfDatesToRequest()]
            ), TimersConfig.activePowerForecastFrequencyInMs),

            timerToLoadActivePowerTimelineValues: setInterval(async () => this.props.dispatchSetPartialIntervalChartData(
            //timerToLoadActivePowerTimelineValues: setTimeout(async () => this.props.dispatchSetPartialIntervalChartData(
              this.props.token,
              [],
              this.props.assetIds, 
              this.props.numberAssetsWithNoMissingIntervals,
              [this.props.activePowerValueTypeId],
              dateHelper.getCurrentTimestamp(),
              (this.props.missingDataIntervalsSelector || []).concat(dateHelper.getIntervalOfLast5MinutesDatesToRequest())
            ), TimersConfig.actualValuesFrequencyInMs),

            timerToLoadTariffValues: setInterval(async () => this.props.dispatchSetPartialIntervalChartData(
            //timerToLoadTariffValues: setTimeout(async () => this.props.dispatchSetPartialIntervalChartData(
              this.props.token,
              [],
              this.props.assetIds, 
              this.props.numberAssetsWithNoMissingIntervals,
              this.props.tariffValueTypeIds,
              dateHelper.getCurrentTimestamp(),
              [dateHelper.getWholeIntervalOfDatesToRequest()]
            ), TimersConfig.tariffFrequencyInMs),

            timerToRefreshPage: setTimeout(() => window.location.reload(), dateHelper.getRemainingTimeToTomorrowInMs()),
          });

        });

      }
    });
  }

  componentWillUnmount() {
    //console.log("componentWillUnmount");
    if (this.state.timerToLoadActualValues)
      clearInterval(this.state.timerToLoadActualValues);
    if (this.state.timerToLoadActivePowerForecastTimelineValues)
      clearInterval(this.state.timerToLoadActivePowerForecastTimelineValues);
    if (this.state.timerToLoadActivePowerTimelineValues)
      clearInterval(this.state.timerToLoadActivePowerTimelineValues);
    if (this.state.timerToLoadTariffValues)
      clearInterval(this.state.timerToLoadTariffValues);
    if (this.state.timerToRefreshPage)
      clearInterval(this.state.timerToRefreshPage);
  }

  render(): JSX.Element {
    const leftAreaCollapsed = this.props.navigationState && this.props.navigationState.leftAreaCollapsed;
    const containerClass = leftAreaCollapsed ? "dashboard__container-leftareacollapsed" : "dashboard__container-leftareaexpanded";

    return (
      <div className={containerClass}>
        <div className="dashboard__container-header">
          <Header />
        </div>
        {/* <div className="columns"> */}
          {
            leftAreaCollapsed ?
              <LeftAreaCollapseExpand /> :
              <LeftArea />
          }
          <Chart />
          <WarningsList />
        {/* </div> */}
      </div>
    );
  }
}


const mapStateToProps = (state: Store): any => ({
  token: userSelectors.getToken(state),
  batches: timelineDataSelectors.batchesSelector(state),
  assetIds: clientAssetsSelectors.getAssetIds(state),
  numberAssetsWithNoMissingIntervals: clientAssetsSelectors.getSelectedAssetIdsWithClient(state).length,
  navigationState: navSelectors.getNavigationState(state),
  valueTypesForTimeline: valueTypesSelectors.getValueTypesForTimeline(state),
  tariffValueTypeIds: valueTypesSelectors.getTariffValueTypeIds(state),
  activePowerValueTypeId: valueTypesSelectors.getActivePowerValueTypeId(state),
  forecastValueTypeId: valueTypesSelectors.getForecastValueTypeId(state),
  valueTypeLookups: valueTypesSelectors.getValueTypeLookups(state),
  missingDataIntervalsSelector: timelineDataSelectors.missingDataIntervalsSelector(state)
});

const mapDispatchToProps = (dispatch: Dispatch<any>): any => ({
  dispatchLoadClientsAndAssets: (token: string) => dispatch(clientsAssetsOperations.loadClientAssetsInfo(token)),
  dispatchLoadValueTypes: (token: string) => dispatch(valueTypeOperations.loadValueTypesInfo(token)),
  dispatchLoadActualValues: (token: string, assetIds: number[], valueTypeLookups: ValueTypeLookup[]) => dispatch(actualValuesOperations.loadActualValues(token, assetIds, valueTypeLookups)),
  dispatchSetCurrentFullInterval: (interval: Interval) => dispatch(timelineDataOperations.setCurrentFullInterval(interval)),
  dispatchSetFullIntervalChartData: (
      token: string,
      batches: any[],
      assetIds: number[],
      numberAssetsWithNoMissingIntervals: number,
      valueTypeIds: number[], 
      currentTimestamp: number,
      fullInterval: Interval) => dispatch(timelineDataOperations.loadFullChartData(
        token, batches, assetIds, numberAssetsWithNoMissingIntervals, valueTypeIds, currentTimestamp, fullInterval)),
  dispatchSetPartialIntervalChartData: (
      token: string,
      batches: any[],
      assetIds: number[], 
      numberAssetsWithNoMissingIntervals: number,
      valueTypeIds: number[], 
      currentTimestamp: number,
      intervals: Interval[]) => dispatch(timelineDataOperations.loadPartialChartData(
        token, batches, assetIds, numberAssetsWithNoMissingIntervals, valueTypeIds, currentTimestamp, intervals)),
});

const Dashboard = connect(mapStateToProps, mapDispatchToProps)(DashBoardPage)

export default Dashboard;
