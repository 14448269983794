interface Error<T> {
    errors: {
        [k in keyof T]: string[]
    },
    status: 400,
    title: string,
    traceId: string,
    type: "error" | "server_error" | "auth_error" | "conflict"
}

interface Success<T> {
    result: T,
    type: "success"
}

export class Api {
    
    static async POST<B = any, S = any, F = Error<B>>(path: string, body: B): Promise<Success<S> | F> {
        const res = await fetch(this.URL(path), {
            body: JSON.stringify(body),
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .catch( (error) => {
            console.error('Error on fetch:', error);
            return null;
          });
        
        return await this.ResponseProcessment<S, F>(res)

    }

    static async GET<S = any, F = Error<S>>(path: string): Promise<Success<S> | F> {
        const res = await fetch(this.URL(path), {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
            }
        })
        .catch( (error) => {
            console.error('Error on fetch:', error);
            return null;
          });
        
        return await this.ResponseProcessment<S, F>(res)

    }

    static async AUTHENTICATED_GET<S = any, F = Error<S>>(path : string, token: string): Promise<Success<S>| F> {
        const res = await fetch(this.URL(path), {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .catch( (error) => {
            console.error('Error on fetch:', error);
            return null;
          });

        return await this.ResponseProcessment<S, F>(res)
    }

    static async AUTHENTICATED_POST<S = any, B = any, F = Error<S>>(path : string,  body : B, token: string): Promise<Success<S>| F> {
        const res = await fetch(this.URL(path), {
            body: JSON.stringify(body),
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .catch( (error) => {
            console.error('Error on fetch:', error);
            return null;
          });

        return await this.ResponseProcessment<S, F>(res)
        
    }

    static URL(path: string): string {
        return [process.env.REACT_APP_API_ROOT, path].join("/")
    }

    static async ResponseProcessment<S = any, F = Error<S>>(res : Response | null): Promise<Success<S>| F> 
    {
        let json = {}

        if (res == null) {
            json = {
                type: "server_error",
            }
            return json as F;
        }

        const text = await res.text()
        if (text.length > 0)
        {
            json = JSON.parse(text)
        }

        if (res.status === 401 || res.status === 403){
            json = {
                ...json,
                type: "auth_error"
            }
            return json as F;
        }

        if (res.status >= 400 && res.status <= 499){
            json = {
                ...json,
                type: "error"
            }
            return json as F;
        }

        if (res.status >= 500 && res.status <= 599) {
            json = {
                ...json,
                type: "server_error",
            }
            return json as F;
        }

        // if (res.status === 409)
        // {
        //     json = {
        //         ...json,
        //         type: "conflict",
        //     }
        //     return json as F;
        // }

        return {
            type:"success",
            result: json as S
        };
    }

}


