
export const addModifiers = (baseClass: string, modifiers?: string[]) => {
  const modifiersArray = modifiers ? modifiers : [];
  const mdfrs = modifiersArray.map((modifier: string) =>
    baseClass.concat('--', modifier),
  );

  return baseClass.concat(' ', mdfrs.join(' '));
};

