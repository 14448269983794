import * as React from 'react';

import { ReactComponent as SunIconSvgComponent } from "../../assets/images/SUN.svg"
import { ReactComponent as WindIconSvgComponent } from "../../assets/images/WIND.svg"
import { ReactComponent as HydroIconSvgComponent } from "../../assets/images/HYDRO.svg"
import { AssetType } from '../../store/ClientsAssetsInfo/types';

interface ComponentProps  {
    assetType: AssetType;
    color: string;
}
  
  
  export const AssetIcon = (props: ComponentProps) => {
    return (
        <div>
        { props.assetType === "HYDRO" &&
            <HydroIconSvgComponent display="block" style={{fill: props.color}} />
        }
        { props.assetType === "SUN" &&
            <SunIconSvgComponent display="block" style={{fill: props.color}} />
        }
        { props.assetType === "WIND" &&
            <WindIconSvgComponent display="block" style={{fill: props.color}} />
        }
        </div>
    )
  };
  