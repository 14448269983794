import * as React from 'react';

import { addModifiers } from '../../../helpers';

import { CheckboxProps } from './types';
import './Checkbox.scss';

const Checkbox = ({
  id,
  name,
  label = null,
  labelColor = null,
  checked = null,
  children = null,
  handleChange,
  handleLabelClick,
  modifiers = [],
  contentModifiers = [],
  disabled = false,
  required = false,
  value=""
}: CheckboxProps): JSX.Element => {
  const checkedAttr = checked !== null ? { checked } : {};
  const labelStyle = {
    color: labelColor ? labelColor : ""
  };
  const onChange = (event: any) => {
    handleChange(event);
  };

  const onLabelClick = (event: any) => {
    if (handleLabelClick) handleLabelClick(event);
  };

  return (
    <div className={addModifiers('checkbox__content', contentModifiers)}>
      {label && (
        <label className="checkbox__label">
          {label}
          {required && <sup className="input__sup">*</sup>}
        </label>
      )}
      <div className="checkbox__wrapper">
        <input
          id={id}
          type="checkbox"
          name={name}
          disabled={disabled}
          onChange={onChange}
          value={value ? value : ""}
          className="checkbox__elem"
          {...checkedAttr}
        />
        <label
          htmlFor={id}
          className={addModifiers('checkbox__label-icon', modifiers)}
        >
        </label>
        {children && 
          <div onClick={onLabelClick} style={labelStyle} className="checkbox__label-text">{children}</div>}
      </div>
    </div>
  );
};

export default Checkbox;
