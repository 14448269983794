import React from 'react';
import { connect } from 'react-redux';
import {  withRouter, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { Dispatch } from 'redux';

import { Authentication } from '../../pages/Authentication';
import { Dashboard } from '../../pages/Dashboard';
import { ErrorBoundary } from '../ErrorBoundary';
import { NotFound } from '../NotFound';
import { ScrollToTop } from '../ScrollToTop';
import { AuthenticatedRoute } from '../AuthenticatedRoute/AuthenticatedRoute';

class App extends React.Component<RouteComponentProps & any> {

  render(): JSX.Element {
    const pathName = this.props.location.pathname.substr(1).replace(/\//g, '-');

    return(
      <ErrorBoundary showUI={true}>
        <div className={`app-${pathName}`}>
          <div className="main">
            <Route component={ScrollToTop}/>
            <Switch>
              <AuthenticatedRoute path="/" exact={true} component={Dashboard} />
              <Route path="/login" exact={true} component={Authentication} />
              <Route component={NotFound}/>
            </Switch>
          </div>
        </div>
      </ErrorBoundary>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>): any => ({
});

export default withRouter(connect<any, any, any, any>(null, mapDispatchToProps)(App));
