import * as Highchart from 'highcharts';
import { getBeginOfTodayTimestamp, getBeginOfTomorrowTimestamp } from '../../helpers/datehelper';
import { IntlShape } from 'react-intl';
import { formatMessageHelper } from '../../helpers/internationalization';

export const getSeries = (
  fare: [number, number | null][],
  productionAndPredictionAreaData: [number, number, number][],
  productionData: [number, number][],
  preditionData: [number, number][],
  endDateOfFare: number,
  intl: IntlShape
): any[] => [
  {
    name: formatMessageHelper(intl, "chart.tooltip.fare"),
    data: fare,
    zones: [
      {
        color: 'white',
        // End of fare line
        value: endDateOfFare,
      },
      {
        color: '#F5A623',
      },
    ],
    zoneAxis: 'x',
    showInLegend: false,
    yAxis: 1,
    step: true,
    tooltip: {
      valueDecimals: 2,
      valueSuffix: " €",
    }
  },
  {
    name: formatMessageHelper(intl, "chart.tooltip.productionvsexpected"),
    type: 'arearange',
    data: productionAndPredictionAreaData,
    color: 'rgb(250,250,250)',
    enableMouseTracking: false,
  },
  {
    title: "Producao",
    name: formatMessageHelper(intl, "chart.tooltip.production"),
    data: productionData,
    showInLegend: false,
    color: '#4A90E2',
    tooltip: {
      valueDecimals: 2,
      valueSuffix: " MW",
    },
  },
  {
    title: "Previsao de Produção",
    name: formatMessageHelper(intl, "chart.tooltip.productionexpected"),
    data: preditionData,
    showInLegend: false,
    color: '#7ED322',
    step: true,
    tooltip: {
      valueDecimals: 2,
      valueSuffix: " MW",
    }
  },
];

export const options = (
  fare: [number, number | null][],
  productionAndPredictionAreaData: [number, number, number][],
  productionData: [number, number][],
  preditionData: [number, number][],
  lastProductionEnergy: number,
  endDateOfFare: number,
  intl: IntlShape
  //labels: ChartLabels
): Highchart.Options => ({
  chart: {
    backgroundColor: 'transparent',
    reflow: true
  },
  time: {
    timezone: 'Europe/London',
  },
  credits: { enabled: false },
  navigator: {
    enabled: false,
  },
  scrollbar: {
    enabled: false,
  },
  rangeSelector: {
    selected: 4,
    inputEnabled: false,
    buttonTheme: {
      visibility: 'hidden',
    },
    labelStyle: {
      visibility: 'hidden',
    },
  },
  yAxis: [
    { // Primary yAxis
      labels: {
        format: '{value} MW',
      },
      softMin: 0,
      startOnTick: false,
      opposite: false,
    },
    { // Secondary yAxis
      gridLineWidth: 0,
      labels: {
        format: '€{value}',
      },
      opposite: true,
    },
  ],
  xAxis: {
    type: 'datetime',
    // Makes 3 hour interval in the xAxis
    tickInterval: 3600 * 3000,
    dateTimeLabelFormats: {
      day: '%H:%M',
    },
    plotBands: [{ // mark the weekend
      color: '#313336',
      from: getBeginOfTodayTimestamp(),
      to: getBeginOfTomorrowTimestamp(),
    }],
    plotLines: [{
      color: 'white',
      width: 1,
      // Date time where the energy production ends
      value: lastProductionEnergy,
      zIndex: 5,
    }],
  },
  series: getSeries(fare, productionAndPredictionAreaData, productionData, preditionData, endDateOfFare, intl),
});
