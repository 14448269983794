import { createActions } from 'redux-actions';

export const {
  userLogout,
  setChartDataRequest,
  setChartDataFailure,
  setChartDataSuccess,
  setCurrentTimestamp,
  setCurrentFullInterval
} = createActions(
 'USER_LOGOUT',
 'SET_CHART_DATA_REQUEST',
 'SET_CHART_DATA_FAILURE',
 'SET_CHART_DATA_SUCCESS',
 'SET_CURRENT_TIMESTAMP',
 'SET_CURRENT_FULL_INTERVAL'
);
