import { handleActions } from 'redux-actions';
import { LoadClientsAssetsInfoActionType, ChangeCheckedStateActionType, AssetInfo, ClientInfo, ClientsAssetsState, ChangeClientDetailsVisibilityActionType, SelectAssetExclusivelyActionType } from './types';

const initialState: ClientsAssetsState = {
  loadDataInfo: {
    isLoadingData: true,
    errorLoadingData: false
  },
  clientsState: [],
  assetsState: [],
  previousSelectedAssetsIds: []
};

const ClientsAssetsInfoReducer = handleActions<any>(
  {
    USER_LOGOUT: (state: any, action: any) => {
      return initialState;
    },

    LOAD_CLIENTS_ASSETS_INFO_REQUEST: (state: any, action: LoadClientsAssetsInfoActionType) => {
      return {
        ...state,
        loadDataInfo: {
          isLoadingData: true,
          errorLoadingData: false
        },
      }
    },

    LOAD_CLIENTS_ASSETS_INFO_FAILURE: (state: any, action: LoadClientsAssetsInfoActionType) => {
      return {
        ...state,
        loadDataInfo: {
          isLoadingData: false,
          errorLoadingData: true
        },
      }
    },

    LOAD_CLIENTS_ASSETS_INFO_SUCCESS: (state: any, action: LoadClientsAssetsInfoActionType) => {

      const newState: ClientsAssetsState = {
        loadDataInfo: {
          isLoadingData: false,
          errorLoadingData: false
        },
        clientsState: [],
        assetsState: [],
        previousSelectedAssetsIds: []
      };

      action.payload.clients.forEach((client: ClientInfo) => {
        newState.clientsState.push({
            clientInfo: client,
            checked: true,
            showingAssets: true
        });
      });

      action.payload.assets.forEach((asset: AssetInfo) => {
        newState.assetsState.push({
            assetInfo: asset,
            checked: true
        });
      });

      return newState;
    },

    CHANGE_ASSET_STATE: (state: any, action: ChangeCheckedStateActionType) => {
      const newState: ClientsAssetsState = {
        ...state
      };

      let assetClientId: number = -1;

      // checks/unchecks the asset
      newState.assetsState.forEach(a => {
        if (a.assetInfo.assetId === action.payload.objId) {
          a.checked = action.payload.newCheckedState;
          if (a.assetInfo.clientId)
            assetClientId = a.assetInfo.clientId
        }
      });

      if (assetClientId !== -1) {
        // checks the client if all assets are checked
        // unchecks the client if at least one asset is unchecked
        newState.clientsState.forEach(c => {
          if (c.clientInfo.clientId === assetClientId) {
            if (action.payload.newCheckedState === false) {
              c.checked = false;
            }
            else { // newCheckedState == true
              const clientAssets = newState.assetsState.filter(a => a.assetInfo.clientId === assetClientId);
              c.checked = 0 === clientAssets.filter(a => a.checked === false).length;
            }
          }
        });
    }

      return newState;
    },

    CHANGE_CLIENT_STATE: (state: any, action: ChangeCheckedStateActionType) => {
      const newState: ClientsAssetsState = {
        ...state
      };

      // checks/unchecks the client
      newState.clientsState.forEach(c => {
        if (c.clientInfo.clientId === action.payload.objId)
          c.checked = action.payload.newCheckedState;
      });

      // and also checks/unchecks the client assets
      newState.assetsState.forEach(a => {
        if (a.assetInfo.clientId === action.payload.objId)
          a.checked = action.payload.newCheckedState;
      });

      return newState;
    },

    CHANGE_CLIENT_DETAILS_VISIBILITY: (state: any, action: ChangeClientDetailsVisibilityActionType) => {
      const newState: ClientsAssetsState = {
        ...state
      };

      newState.clientsState.forEach(c => {
        if (c.clientInfo.clientId === action.payload.clientId) {
          c.showingAssets = action.payload.visible;
        }
      });

      return newState;
    },

    SELECT_ASSET_EXCLUSIVELY: (state: any, action: SelectAssetExclusivelyActionType) => {
      const newState: ClientsAssetsState = {
        ...state,
        previousSelectedAssetsIds: []
      };

      // checks/unchecks the asset
      newState.assetsState.forEach(a => {
        if (a.checked)
          newState.previousSelectedAssetsIds.push(a.assetInfo.assetId);
        a.checked = a.assetInfo.assetId === action.payload.assetId || !a.assetInfo.clientId;
      });
      return newState;
    },

    RESET_TO_PREVIOUS_ASSETS_SELECTION: (state: ClientsAssetsState, action: any) => {
      const newState: ClientsAssetsState = {
        ...state
      };

      // checks/unchecks the asset
      newState.assetsState.forEach(a => {
        a.checked = state.previousSelectedAssetsIds.findIndex(assetId => assetId === a.assetInfo.assetId) !== -1;
      });

      newState.previousSelectedAssetsIds = [];
      return newState;
    },

  },
  initialState,
);

export default ClientsAssetsInfoReducer;
