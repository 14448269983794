import * as React from 'react';

import './AssetDetailsTitle.scss';
import { AssetType } from '../../store/ClientsAssetsInfo/types';
import { AssetIcon } from '../AssetIcon/AssetIcon';
import LeftAreaCollapseExpand from '../LeftAreaCollapseExpand/LeftAreaCollapseExpand';


interface TitlesProps  {
  title: string;
  color: string;
  assetType: AssetType;
}


export const AssetDetailsTitle = (props: TitlesProps) => {
  const titleStyle = {
    color: props.color,
  };
  
  return (
    <h2 className="assetdetails-title-wrapper">
      <div className="assetdetails-top">
        <div className="assetdetails-title" style={titleStyle}>{props.title}</div>
        <div className="assetdetails-icon">
          <AssetIcon assetType={props.assetType} color={props.color} />
        </div>
        <div className="assetdetails-collapse">
          <LeftAreaCollapseExpand />
        </div>
      </div>
    </h2>
  )
};
