import * as React from 'react';
import { FormattedMessageHelper } from '../../helpers/internationalization';
import { Checkbox } from '../Forms/Checkbox';

import  './ClientsListItem.scss';
import { ClientState, ClientsAssetsState } from '../../store/ClientsAssetsInfo/types';
import AssetsList from '../AssetsList/AssetsList';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import operations from '../../store/ClientsAssetsInfo/operations';
import { Store } from '../../helpers/types';
import  * as clientAssetsSelectors  from '../../store/ClientsAssetsInfo/selectors';
import  * as valueTypesInfoSelectors  from '../../store/ValueTypesInfo/selectors';
import  * as actualValuesSelectors  from '../../store/ActualValues/selectors';
import { ProgressBar } from '../ProgressBar/ProgressBar';
import * as valueTypesHelper from '../../helpers/valuetypeshelper';
import { AssetActualValuesState } from '../../store/ActualValues/types';
import { ValueTypeInfo } from '../../store/ValueTypesInfo/types';

interface ComponentProps {
    clientId: number,
    clientAndAssets?: ClientsAssetsState,
    valueTypesInfo?: ValueTypeInfo[],
    actualValues?: AssetActualValuesState,
    selectedAssets?: { assetId: number, assetName: string }[],
    dispatchChangeClientState?: any,
    dispatchCollapseClient?: any,
    dispatchExpandClient?: any
}

interface State {
}

class ClientsListItemComponent extends React.Component<ComponentProps, State> {

    handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
        //console.log( "client event", event.target.value, event.target.checked );
        this.props.dispatchChangeClientState(Number(event.target.value), event.target.checked);
    };

    handleCollapseClick = (event: any) => {
        //console.log( "handleCollapseClick");
        this.props.dispatchCollapseClient(this.props.clientId);
    };

    handleExpandClick = (event: any) => {
        //console.log( "handleExpandClick");
        this.props.dispatchExpandClient(this.props.clientId);
    };

    getClient(): ClientState | undefined {
        if (this.props.clientAndAssets && this.props.clientAndAssets.clientsState) {
            return this.props.clientAndAssets.clientsState.find(c => c.clientInfo.clientId === this.props.clientId);
        }
        return undefined;
    }

    getClientAssets(): number[] {
        return this.props.clientAndAssets ?
                (this.props.clientAndAssets.assetsState.filter(a => a.assetInfo.clientId === this.props.clientId)
                    .map(a => a.assetInfo.assetId)) :
                [];
    }

    getSelectedClientAssets(): number[] {
        const selectedAssets = this.props.selectedAssets || [];

        return this.getClientAssets()
                .filter(a => selectedAssets.findIndex(sa => sa.assetId === a) !== -1);
    }


    getClientActualValues(): any {
        const clientAssetsIds: number[] = this.getClientAssets();
        const clientSelectedAssetIds: number[] = this.getSelectedClientAssets();
        const actualValues = this.props.actualValues ? this.props.actualValues.values : [];

        return {
            numOfClientAssets: clientAssetsIds.length,
            activePower: valueTypesHelper.getSumOfActualValueAndUnit(
                            clientAssetsIds, "Asset.ActivePower", this.props.valueTypesInfo, actualValues),
            maxActivePower: valueTypesHelper.getSumOfActualValueAndUnit(
                clientAssetsIds, "Asset.MaxActivePower", this.props.valueTypesInfo, actualValues),
            selectedActivePower: valueTypesHelper.getSumOfActualValueAndUnit(
                clientSelectedAssetIds, "Asset.ActivePower", this.props.valueTypesInfo, actualValues),
            selectedMaxActivePower: valueTypesHelper.getSumOfActualValueAndUnit(
                clientSelectedAssetIds, "Asset.MaxActivePower", this.props.valueTypesInfo, actualValues),
            equipsOkPercentage: valueTypesHelper.getEquipsOkPercentage(clientSelectedAssetIds, this.props.valueTypesInfo, actualValues),
        };
    }

    
    render(): JSX.Element {
        const client = this.getClient();
        if (!client)
            return <div />

        const values = this.getClientActualValues();
        const powerPercentage = valueTypesHelper.getPercentageForValueAndUnits(values.selectedActivePower, values.selectedMaxActivePower);

        const maxActivePowerDisplayValue = valueTypesHelper.buildValueAndUnitJSXElement(
            <span>
                {valueTypesHelper.formatNumericValueToDisplay(values.maxActivePower) + " " + valueTypesHelper.formatUnitToDisplay(values.maxActivePower)}
            </span>,
            values.maxActivePower);

        const selectedActivePowerDisplayValue = valueTypesHelper.buildValueAndUnitJSXElement(
            <span>
                {valueTypesHelper.formatNumericValueToDisplay(values.selectedActivePower) + " " + valueTypesHelper.formatUnitToDisplay(values.selectedActivePower)}
            </span>,
            values.selectedActivePower);

        const selectedMaxActivePowerDisplayValue = valueTypesHelper.buildValueAndUnitJSXElement(
            <span>
                {valueTypesHelper.formatNumericValueToDisplay(values.selectedMaxActivePower) + " " + valueTypesHelper.formatUnitToDisplay(values.selectedMaxActivePower)}
            </span>,
            values.selectedMaxActivePower);

        const iconEquipsOkCssClass = 
            values.equipsOkPercentage === 0 ? 
            "clients__icon--fail" :
            (values.equipsOkPercentage === 100 ? "clients__icon--success": "clients__icon--warning");
            
        return(
            <div className="clients__item">                
                <div className="clients__header">
                    <div className="clients__header-top">
                        <div className="left">
                            <Checkbox
                                id={"client_filter" + client.clientInfo.clientId.toString()}
                                name={"client_filter" + client.clientInfo.clientId.toString()}
                                value={client.clientInfo.clientId.toString()}
                                checked={client.checked}
                                handleChange={this.handleFilter}
                                >
                                {client && client.clientInfo.clientName}
                            </Checkbox>
                        </div>
                        <div className="text-column">
                                <label className="clients__header-label">
                                    <FormattedMessageHelper id="clients.actives"/>
                                </label>: { values.numOfClientAssets }
                            </div>
                            <div className="text-column">
                                <label>
                                    <FormattedMessageHelper id="clients.production"/>
                                </label>: { maxActivePowerDisplayValue }
                            </div>
                        { client.showingAssets &&
                            <div className="right expanded" onClick={this.handleCollapseClick}>
                            </div>
                        }
                        { !client.showingAssets &&
                            <div className="right collapsed" onClick={this.handleExpandClick}>
                            </div>
                        }
                    </div>
                    <div className="clients__header-content">
                        <div className="clients__header-text">
                            <div className="text-column">
                                <label>
                                    <FormattedMessageHelper id="clients.selection"/>
                                </label>: { selectedActivePowerDisplayValue } / {selectedMaxActivePowerDisplayValue}
                            </div>
                            <div className="production-bar-column">
                                <ProgressBar 
                                    activePercentage={ powerPercentage } 
                                    color="#AAAAAA"/>
                            </div>
                            <div className={"clients__icon " + iconEquipsOkCssClass}></div>
                        </div>
                    </div>
                </div>
                
                { client && client.showingAssets &&
                    <AssetsList clientId={client.clientInfo.clientId} />
                }
            </div>
        );
    }
}

const mapStateToProps = (state: Store): any => ({
    clientAndAssets: clientAssetsSelectors.getClientsAndAssets(state),
    valueTypesInfo: valueTypesInfoSelectors.getValueTypes(state),
    actualValues: actualValuesSelectors.getActualValues(state),
    selectedAssets: clientAssetsSelectors.getSelectedAssets(state),
  });

const mapDispatchToProps = (dispatch: Dispatch<any>): any => ({
    dispatchChangeClientState: (clientId: number, checked: boolean) => dispatch(operations.changeClientState(clientId, checked)),
    dispatchCollapseClient: (clientId: number) => dispatch(operations.changeClientDetailsVisibility(clientId, false)),
    dispatchExpandClient: (clientId: number) => dispatch(operations.changeClientDetailsVisibility(clientId, true))
  });

const ClientsListItem = connect(mapStateToProps, mapDispatchToProps)(ClientsListItemComponent)

export default ClientsListItem;
