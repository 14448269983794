import { Dispatch } from "redux";

import { Api } from "../../lib/API";
import * as TimelineDataActions from './action';
import * as UserActions from '../User/action';
import { GetChartDataResult, GetChartDataParams, Interval } from "./types";

const loadFullChartData = (
        token: string,
        batches: any[],
        assetIds: number[], 
        numberAssetsWithNoMissingIntervals: number,
        valueTypeIds: number[], 
        currentTimestamp: number,
        fullInterval: Interval) => {

    //console.log("loadFullChartData");
    return loadChartData(
        token,
        batches,
        assetIds,
        numberAssetsWithNoMissingIntervals,
        valueTypeIds,
        false,
        currentTimestamp,
        [fullInterval],
        fullInterval
    );
}

const loadPartialChartData = (
        token: string,
        batches: any[],
        assetIds: number[], 
        numberAssetsWithNoMissingIntervals: number,
        valueTypeIds: number[], 
        currentTimestamp: number,
        intervals: Interval[]) => {

    //console.log("loadPartialChartData");
    return loadChartData(
        token,
        batches,
        assetIds,
        numberAssetsWithNoMissingIntervals,
        valueTypeIds,
        false,
        currentTimestamp,
        intervals,
        undefined
    );
}

const loadChartData = (
        token: string,
        batches: any[],
        assetIds: number[], 
        numberAssetsWithNoMissingIntervals: number,
        valueTypeIds: number[], 
        treatAsSingleInterval: boolean,
        currentTimestamp: number,
        timeIntervals: Interval[],
        fullInterval?: Interval) => async (dispatch : Dispatch) => {

    let result : GetChartDataResult = { 
        results: [],
    }

    //console.log("loadChartData - timeIntervals", timeIntervals);
    //console.log("loadChartData - valueTypeIds", valueTypeIds);
    
    if(assetIds.length > 0 && valueTypeIds.length > 0) {

        let body: GetChartDataParams = {
            treatasSingleInterval: treatAsSingleInterval,
            batches: batches,
            assetIds: assetIds,
            valueTypeIds: valueTypeIds,
            intervals: []
        }

        body.intervals = timeIntervals;
        //console.log("loadChartData - request body", body);

        dispatch(TimelineDataActions.setChartDataRequest());
        const response = await Api.AUTHENTICATED_POST<GetChartDataResult, GetChartDataParams, any>('api/v1/measures/timeline', body, token);

        //console.log("loadChartData - response", response);

        if (response.type === "auth_error") 
        {
            dispatch(UserActions.userLogoutSuccess());
        }
        else if (response.type === "error" || response.type === "server_error")
        {
            dispatch(TimelineDataActions.setChartDataFailure());
        }
        else
        {
            dispatch(TimelineDataActions.setChartDataSuccess({
              batches: response.result.batches,
              currentTimestamp: currentTimestamp,
              currentFullInterval: fullInterval,
              dataPoints: response.result.results,
              numberAssetsExpected: numberAssetsWithNoMissingIntervals,
            }));
        }
    }

    return result
}

const setCurrentTimestamp = (timestamp: number) => async (dispatch : Dispatch) => {
  dispatch(TimelineDataActions.setCurrentTimestamp(timestamp));
}

const setCurrentFullInterval = (interval: Interval) => async (dispatch : Dispatch) => {
  dispatch(TimelineDataActions.setCurrentFullInterval(interval));
}

const operations = {
    loadFullChartData,
    loadPartialChartData,
    setCurrentTimestamp,
    setCurrentFullInterval
};

export default operations;