import { createActions } from 'redux-actions';

export const {
  setLeftAreaView,
  collapseLeftAreaView,
  expandLeftAreaView
} = createActions(
 'SET_LEFT_AREA_VIEW',
 'COLLAPSE_LEFT_AREA_VIEW',
 'EXPAND_LEFT_AREA_VIEW'
);
