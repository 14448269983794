import * as React from 'react';

import  './ProgressBar.scss';

interface ComponentProps  {
    activePercentage: number,
    color: string,
    activeSetpoint?: number
}
  
  
  export const ProgressBar = (props: ComponentProps) => {

    const innerBarStyle = {
      width: props.activePercentage + "%",
      backgroundColor: props.color
    };

    const setpointStyle = {
      left: props.activeSetpoint + "%"
    };

    return (
        <div className="progressbar__totalvalue-bar">
            <div className="progressbar__actualvalue-bar" style={innerBarStyle}>
            </div>

            {
            props.activeSetpoint !== undefined &&
            <div className="progressbar__setpoint" style={setpointStyle}>
            </div>
            }
            
        </div>
    )
  };
  