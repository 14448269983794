import * as React from 'react';

import { ErrorBoundary } from '../ErrorBoundary';
import { Title } from '../Title';
import WarningsListItem, { WarningProps } from '../WarningsListItem/WarningsListItem';

import  './WarningsList.scss';
import { connect } from 'react-redux';
import { Store } from '../../helpers/types';
import  * as clientAssetsSelectors  from '../../store/ClientsAssetsInfo/selectors';
import  * as valueTypesInfoSelectors  from '../../store/ValueTypesInfo/selectors';
import  * as actualValuesSelectors  from '../../store/ActualValues/selectors';
import  * as timelineDataSelectors  from '../../store/TimelineData/reselectors';
import { ValueTypeInfo } from '../../store/ValueTypesInfo/types';
import { AssetActualValuesState } from '../../store/ActualValues/types';
import { timestampToDate } from '../../helpers/datehelper';
import { Interval } from '../../store/TimelineData/types';
import { FormattedMessageHelper } from '../../helpers/internationalization';
import { LoadDataInfo } from '../../store/types';
import { DataLoadManager } from '../DataLoadManager/DataLoadManager';


interface ComponentProps {
  clientAndAssetsLoadInfo?: LoadDataInfo,
  valueTypesLoadInfo?: LoadDataInfo,
  selectedAssets?: { assetId: number, assetName: string }[],
  valueTypesWithAlarmsConfigured?: ValueTypeInfo[],
  actualValues?: AssetActualValuesState,
  currentFullInterval?: Interval
}

interface State {
}

class WarningsListComponent extends React.Component<ComponentProps, State> {

  getWarnings = (): WarningProps[] => {
      const selectedAssets = this.props.selectedAssets;
      const valueTypesWithAlarmsConfigured = this.props.valueTypesWithAlarmsConfigured;
      const assetsActualValues = this.props.actualValues ? this.props.actualValues.values : null;
      const currentFullInterval = this.props.currentFullInterval;

      const warnings: WarningProps[] = [];

      if (!selectedAssets || selectedAssets.length === 0 ||
          !valueTypesWithAlarmsConfigured || valueTypesWithAlarmsConfigured.length === 0 ||
          !assetsActualValues || assetsActualValues.length === 0 ||
          !currentFullInterval) {
        return warnings;
      }

      selectedAssets.forEach(a => {
        const assetActualValues = assetsActualValues.find(aav => aav.assetId === a.assetId);
              
        if (assetActualValues) {
            assetActualValues.actualValues
              .filter(aav => aav.value)
              .forEach(av => {
                const valueTypeInfo = valueTypesWithAlarmsConfigured.find(vt => vt.valueTypeId === av.valueTypeId);
                if (valueTypeInfo) {
                  const firstAlarmfound = valueTypeInfo.alarms
                    .find(al => av.value && al.flagMin < av.value && av.value <= al.flagMax && 
                        (av.timestamp && currentFullInterval.s < av.timestamp && av.timestamp <= currentFullInterval.f ) );
                  if (firstAlarmfound) {
                    warnings.push({
                      key: `${a.assetId}-${av.valueTypeId}`,
                      message: `${a.assetName} ${firstAlarmfound.displayText}`,
                      warningColor: firstAlarmfound.displayColor,
                      date: timestampToDate(av.timestamp),
                    });    
                  }
                }
              });
          }
      });

      return warnings;
  }

  render(): JSX.Element {
    const isLoadingData = (this.props.clientAndAssetsLoadInfo !== undefined && this.props.clientAndAssetsLoadInfo.isLoadingData) ||
      (this.props.valueTypesLoadInfo !== undefined && this.props.valueTypesLoadInfo.isLoadingData) ||
      (this.props.actualValues !== undefined && this.props.actualValues.loadDataInfo.isLoadingData);

    const errorLoadingData = (this.props.clientAndAssetsLoadInfo !== undefined && this.props.clientAndAssetsLoadInfo.errorLoadingData) ||
      (this.props.valueTypesLoadInfo !== undefined && this.props.valueTypesLoadInfo.errorLoadingData) ||
      (this.props.actualValues !== undefined && this.props.actualValues.loadDataInfo.errorLoadingData);

    const warningsList = this.getWarnings();

    return (
      <ErrorBoundary showUI={true}>
        <div className="alerts">
          <Title title="warnings.title"/>

          <DataLoadManager isLoadingData={isLoadingData} errorLoadingData={errorLoadingData}>
            { warningsList.length > 0 ?
            <ul className="alerts__list">
              { warningsList.map( warning => 
                  <WarningsListItem {...warning} />
              )}
            </ul>
            :
            <div className="dashboard__nodata-message"><FormattedMessageHelper id="warnings.nodatamessage" /></div>
            }
          </DataLoadManager>
        </div>
      </ErrorBoundary>
    )
  }
}

const mapStateToProps = (state: Store): any => ({
  clientAndAssetsLoadInfo: clientAssetsSelectors.getLoadInfo(state),
  valueTypesLoadInfo: valueTypesInfoSelectors.getLoadInfo(state),
  selectedAssets: clientAssetsSelectors.getSelectedAssets(state),
  valueTypesWithAlarmsConfigured: valueTypesInfoSelectors.getValueTypesWithAlarmsConfigured(state),
  actualValues: actualValuesSelectors.getActualValues(state),
  currentFullInterval: timelineDataSelectors.currentFullIntervalSelector(state)
});


const WarningsList = connect(mapStateToProps, null)(WarningsListComponent)

export default WarningsList;
