import * as React from 'react';
import Login from '../../components/Login/Login';

import  './Authentication.scss';

export const Authentication = () => (
    <div className="authentication__container">
        <Login />
    </div>
)
